import {FormControl, FormLabel, Input, Link, Typography} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import Button from "@mui/joy/Button";
import LoginForm from "../forms/login-form";
import * as React from "react";
import ApiClient from "../utils/api-client";
import RegisterForm from "../forms/register-form";
import {useContext, useState} from "react";
import AuthContext from "../contexts/auth-context";
import {useLocation, useNavigate} from "react-router-dom";
import NotificationContext from "../contexts/notification-context";

export default function SignUpPage() {
    const location = useLocation();
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const {sendError, clear} = useContext(NotificationContext);
    const error = useState(null);

    const userFromParameters = () => {
        let params = new URLSearchParams(location.search);
        let email = params.get("email");
        return (email !== null) ? { email } : {};
    }

    const secretKeyFromParameters = () => {
        let params = new URLSearchParams(location.search);
        let key = params.get("key");
        return (key !== null) ? key : "";
    }

    const signUp = async function (newUser) {
        try {
            const client = new ApiClient();
            if (newUser.password !== newUser.passwordValidation) {
                throw new Error("Passwords don't match!");
            }
            // For now, to allow a registration we need to add the key passed as parameter.
            newUser.secretKey = secretKeyFromParameters();
            await client.register(newUser, false);
            navigate("/sign-in");
        } catch (e) {
            sendError(e);
        }
    }

    return (
        <>
            <RegisterForm user={userFromParameters()} onSubmit={(newUser) => signUp(newUser)}
                          error={error}/>
        </>
    );
}
