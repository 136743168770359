import Button from "@mui/joy/Button";
import {Alert, Avatar, Divider, Grid, Link, Stack, Tab, TabList, TabPanel, Tabs, Typography} from "@mui/joy";
import RichTextEditor from "../../components/rich-text-editor";
import {useParams, useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import ApiClient from "../../utils/api-client";
import NotificationContext from "../../contexts/notification-context";
import WikiSummaryFrame from "../../components/wiki-summary-frame";
import DOMPurify from 'dompurify';
import BreadcrumbNavigation from "../../components/breadcrumb-navigation";
import {HelpPageForm} from "../../forms/help-page-form";
import SettingsAccountFragment from "./settings-account-fragment";
import SettingsAccountForm from "../../forms/settings-account-form";
import SettingsAuthenticationForm from "../../forms/settings-authentication-form";
import SettingsDisplayForm from "../../forms/settings-display-form";
import {faInfoSquare} from "@fortawesome/pro-solid-svg-icons";
import RaidLeadAvatar from "../../components/raidlead-avatar";

export default function AdminEditUserFragment() {
    let { id } = useParams();
    const [targetUser, setTargetUser] = useState({ roles: []});
    const { sendError, sendSuccess } = useContext(NotificationContext);

    const fetchTargetedUser = async () => {
        const client = new ApiClient();
        try {
            const user = await client.getUser(id);
            console.log(user);
            setTargetUser(user);
        }
        catch (e) {
            sendError(e);
        }
    }

    useEffect(() => {
        fetchTargetedUser();
    }, []);

    return (
        <>
            <Typography level="title-lg"
                        startDecorator={<RaidLeadAvatar size="lg" anonymous={targetUser.roles.includes('waitlist')} />}>
                {targetUser.roles.includes('waitlist') ? (
                    <Alert color="primary" startDecorator={<FontAwesomeIcon icon={faInfoSquare} />}>
                        This user is on wait list
                    </Alert>
                ) : (
                    <>{targetUser.pseudo}</>
                )}
            </Typography>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Tabs aria-label="Basic tabs" defaultValue={0} variant="plain">
                <TabList>
                    <Tab>Account</Tab>
                    {!targetUser.roles.includes('waitlist') &&
                        <>
                        <Tab>Authentication</Tab>
                        <Tab>Settings</Tab>
                        </>
                    }
                </TabList>
                <TabPanel value={0} variant="soft">
                    <SettingsAccountForm user={targetUser} />
                </TabPanel>
                <TabPanel value={1} variant="soft">
                    <SettingsAuthenticationForm user={targetUser} />
                </TabPanel>
                <TabPanel value={2} variant="soft">
                    <SettingsDisplayForm settings={targetUser.settings} />
                </TabPanel>
            </Tabs>
        </>
    );
}
