import {Grid, Stack} from "@mui/joy";
import PlayerFrame from "./player-frame";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "@mui/joy/Button";
import { faFileImport, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Sheet from "@mui/joy/Sheet";
import React, {useEffect} from 'react';
import {importBase64} from "../../data/ImporterLogic";
import ImportDialog from "./import-dialog";
import NotificationContext from "../contexts/notification-context";

export default function RaidFrame(props) {
    const [importOpen, setImportOpen] = React.useState(false);
    const [raid, setRaid] = React.useState([]);
    const { sendError, sendSuccess } = React.useContext(NotificationContext);

    const proceedImport = (contents) => {
        try {
            const importedRaid = importBase64(contents);
            setRaid(importedRaid.players);
            localStorage.setItem("raid", JSON.stringify(importedRaid));
            setImportOpen(false);
        }
        catch (e) {
            sendError(e);
            setImportOpen(false);
        }
    }

    useEffect(() => {
        const contents = localStorage.getItem("raid");
        if (contents != null && contents !== "undefined") {
            const savedRaid = JSON.parse(contents);
            setRaid(savedRaid.players);
        }
    }, []);

    useEffect(() => {
        if (props.onChange != null) {
            props.onChange({value: raid});
        }
    }, [raid]);

    /*const exampleRaid = [];
    for (let i=1; i < 24; i++) {
        exampleRaid.push({
            "name": "raid" + i,
            "class": CLASSES[Math.floor(Math.random()*CLASSES.length)],
            "spec": "brm",
        })
    }
    setRaid(exampleRaid);*/

    return (
        <Sheet style={{ backgroundColor: "transparent" }}>
            <Stack direction="column">
                <Stack direction="row" spacing={1} sx={{ mb: "20px", justifyContent: 'flex-end' }}>
                    <Button color="primary"
                            size="md"
                            startDecorator={<FontAwesomeIcon icon={faFileImport} />}
                            onClick={() => setImportOpen(true)}>Import</Button>
                </Stack>
                <Grid container spacing={0.5} sx={{ flexGrow: 1 }}>
                    {raid.map((player) => (
                        <Grid xs={12/5}  key={player.name}>
                            <PlayerFrame player={player} />
                        </Grid>
                    ))}
                </Grid>
            </Stack>
            <ImportDialog open={importOpen} onClose={() => setImportOpen(false)} onImport={(data) => proceedImport(data)}/>
        </Sheet>
    );
}
