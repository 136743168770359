import WoWIcon from "./wow-icon";
import {Input} from "@mui/joy";
import {useEffect, useState} from "react";
import ApiClient from "../utils/api-client";

export default function IconInput(props) {
    const [iconName, setIconName] = useState("inv_misc_questionmark.jpg");
    const fetchIconName = async function(id) {
        const client = new ApiClient();
        try {
            const iconInfo = await client.getIconInfo(id);
            setIconName(iconInfo.fileName);
        }
        catch (e) {
            console.error(e);
            setIconName("inv_misc_questionmark.jpg");
        }
    }

    useEffect(() => {
        fetchIconName(props.value);
    }, []);

    const handleChange = function(e) {
        fetchIconName(e.target.value);
        props.onChange(e);
    }

    return (
        <Input
            type="text"
            label="IconId"
            variant={props.variant}
            startDecorator={<WoWIcon iconId={props.value} />}
            value={props.value}
            onChange={handleChange}
        />
    );
}

