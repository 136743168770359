import {Alert, Grid, Stack} from "@mui/joy";
import PlayerFrame from "./player-frame";
import React from "react";
import NotificationContext from "../contexts/notification-context";
import Sheet from "@mui/joy/Sheet";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faWarning} from "@fortawesome/free-solid-svg-icons";
import {motion, AnimatePresence} from "framer-motion"

export default function NotificationsHolder() {
    const { alerts } = React.useContext(NotificationContext);
    const getIconForAlert = function(alert) {
        if (alert.type === "success") {
            return <FontAwesomeIcon icon={faCheck} />
        } else {
            return <FontAwesomeIcon icon={faWarning} />
        }
    }

    return (
        <Sheet style={{ position: "fixed", right: "25px", top: "75px", backgroundColor: "transparent", zIndex: 9999 }}>
            <Stack direction="column" gap={2}>
                <AnimatePresence>
                {alerts.map((alert) => (
                    <motion.div key={alert.id}
                                initial={{ opacity: 0, scale: 0.5 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.5 }}
                                transition={{
                                    type: "spring",
                                    stiffness: 260,
                                    damping: 20
                                }}>
                        <Alert
                               variant="soft"
                               size="lg"
                               style={{ width: "300px" }}
                               color={alert.type}
                               startDecorator={getIconForAlert(alert)}>{alert.text}</Alert>
                    </motion.div>
                ))}
                </AnimatePresence>
            </Stack>
        </Sheet>
    );
}
