import {Alert, Checkbox, Divider, FormControl, FormHelperText, FormLabel, Input, Stack, Typography} from "@mui/joy";
import WoWIcon from "../components/wow-icon";
import Button from "@mui/joy/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDisplay, faInfo, faSave} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import ApiClient from "../utils/api-client";

export default function SettingsAccountForm(props) {
    const [pseudo, setPseudo] = useState(props.user.pseudo);
    const [email, setEmail] = useState(props.user.email);

    const handleSaveClicked = function(e) {
        props.onSave({ pseudo, email });
    }

    useEffect(() => {
        setPseudo(props.user.pseudo);
        setEmail(props.user.email);
    }, [props.user]);

    return (
        <>
            <Stack direction="column" gap={2}>
                <FormControl>
                    <FormLabel>Username</FormLabel>
                    <Input placeholder="Username goes here" readOnly value={props.user.pseudo} />
                    <FormHelperText>The username is unique.</FormHelperText>
                </FormControl>
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input placeholder="Email goes here" readOnly value={props.user.email} />
                    <FormHelperText>Your email is <u>never</u> presented to other users.</FormHelperText>
                </FormControl>
            </Stack>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }}/>
            <Stack direction="row" style={{ marginTop: "20px" }} gap={2} justifyContent="flex-end">
                <Button variant="soft" color="neutral">Cancel</Button>
                <Button variant="solid"
                        color="primary"
                        startDecorator={<FontAwesomeIcon icon={faSave} />}
                        onClick={handleSaveClicked}>Save</Button>
            </Stack>
        </>
    );
}
