import Button from "@mui/joy/Button";
import {Grid, Link, Stack, Table, Typography} from "@mui/joy";
import RichTextEditor from "../components/rich-text-editor";
import {useParams, useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import ApiClient from "../utils/api-client";
import NotificationContext from "../contexts/notification-context";
import WikiSummaryFrame from "../components/wiki-summary-frame";
import DOMPurify from 'dompurify';
import BreadcrumbNavigation from "../components/breadcrumb-navigation";
import WoWIcon from "../components/wow-icon";
import Sheet from "@mui/joy/Sheet";

export default function GameConfigPage() {
    let { id } = useParams();
    const [searchParams] = useSearchParams();
    const [gameConfig, setGameConfig] = useState({ classes: {}});
    const { sendError } = useContext(NotificationContext);

    const fetchGameConfig = async () => {
        const client = new ApiClient();
        try {
            const gameConfig = await client.getGameConfig(id);
            setGameConfig(gameConfig);
        }
        catch (e) {
            sendError(e);
        }
    }

    useEffect(() => {
        fetchGameConfig();
    }, []);

    return (
        <>
            <BreadcrumbNavigation>
                <Link color="neutral">
                    Game configs
                </Link>
                <Link color="neutral">
                    {gameConfig.name}
                </Link>
            </BreadcrumbNavigation>
            <Grid container spacing={1}>
                <Grid xs={12} sm={12} md={9} >
                    <Typography level="h1">{gameConfig.name}</Typography>
                    <Typography level="body-sm">Works with the World of Warcraft version {gameConfig.wowClientVersion}</Typography>
                    <br/>
                    <Typography level="body-sm">You can find here a list of passives, abilities for each class and specialization and the tags associated with each ability:</Typography>

                    <Sheet sx={{ height: "60vh", overflow: 'auto' }}>
                        <Table stickyHeader
                               size="sm" sx={{
                            '& thead th:nth-child(1)': { width: '30%' },
                            '& thead th:nth-child(2)': { width: '80px' },
                            '& thead th:nth-child(4)': { width: '80px' },
                            '& thead th:nth-child(5)': { width: '80px' },
                            '& thead th:nth-child(6)': { width: '80px' },
                        }}>
                            <thead>
                            <tr>
                                <th>Ability name</th>
                                <th>Target</th>
                                <th>Tags</th>
                                <th>Charges</th>
                                <th>Cooldown</th>
                                <th>Efficiency</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.keys(gameConfig.classes).map((key, index) => (
                                <React.Fragment key={index}>
                                    <tr key={index} style={{ background: '#222' }}>
                                        <td colSpan={6}>
                                            <Typography level="body-sm"
                                                        fontWeight="bold"
                                                        fontStyle="italic"
                                                        startDecorator={<WoWIcon iconId={gameConfig.classes[key].iconId} />}>
                                                {key}
                                            </Typography>
                                        </td>
                                    </tr>
                                    {gameConfig.classes[key].passives.map((passive, index2) => (
                                        <tr key={index + "-" + index2}>
                                            <td>
                                                <Typography level="body-xs"
                                                            startDecorator={<WoWIcon iconId={passive.iconId} />}>
                                                    {(passive.variant !== "") ? (
                                                        <><span style={{ color: "white" }}>Passive:</span>&nbsp;{passive.name} • {passive.variant}</>
                                                    ) : (
                                                        <><span style={{ color: "white" }}>Passive:</span>&nbsp;{passive.name}</>
                                                    )}

                                                </Typography>
                                            </td>
                                            <td></td>
                                            <td>{passive.tags}</td>
                                            <td></td>
                                            <td></td>
                                            <td>{passive.efficiency}</td>
                                        </tr>
                                    ))}
                                    {gameConfig.classes[key].abilities.map((ability, index2) => (
                                        <tr key={index + "-" + index2}>
                                            <td>
                                                <Typography level="body-xs"
                                                            startDecorator={<WoWIcon iconId={ability.iconId} />}>
                                                    {(ability.variant !== "") ? (
                                                        <><span style={{ color: "white" }}>Ability:</span>&nbsp;{ability.name} • {ability.variant}</>
                                                    ) : (
                                                        <><span style={{ color: "white" }}>Ability:</span>&nbsp;{ability.name}</>
                                                    )}

                                                </Typography>
                                            </td>
                                            <td>{ability.target}</td>
                                            <td>{ability.tags}</td>
                                            <td>{ability.stacks}</td>
                                            <td>{ability.cooldown}s</td>
                                            <td>{ability.efficiency}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                            </tbody>
                        </Table>
                    </Sheet>

                </Grid>
                <Grid xs={12} sm={12} md={3}>
                    <WikiSummaryFrame section="game-configs" selected={id} />
                </Grid>
            </Grid>
        </>
    );
}
