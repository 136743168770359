import Sheet from "@mui/joy/Sheet";
import {Modal, ModalClose, Stack, Textarea, Typography} from "@mui/joy";
import Button from "@mui/joy/Button";
import React from "react";

export default function ImportDialog({ open, onClose, onImport }) {
    const [data, setData] = React.useState("");

    const handleInputChange = (event) => {
        setData(event.target.value);
    }

    return (
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            size="lg"
            open={open}
            onClose={onClose}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Sheet
                variant="outlined"
                sx={{
                    width: 800,
                    maxWidth: 800,
                    borderRadius: 'md',
                    p: 3,
                    boxShadow: 'lg',
                }}
            >
                <ModalClose variant="plain" sx={{ m: 1 }} />
                <Typography
                    component="h2"
                    id="modal-title"
                    level="h4"
                    textColor="inherit"
                    fontWeight="lg"
                    mb={1}
                >
                    Import Raid Composition
                </Typography>
                <Textarea minRows={10} sx={{ mb: "15px", maxHeight: "250px" }} defaultValue={data} onChange={handleInputChange}  />
                <Stack direction="row" spacing={1}>
                    <Button color="success" onClick={() => onImport(data)}>OK</Button>
                    <Button color="neutral" onClick={onClose}>Cancel</Button>
                </Stack>
            </Sheet>
        </Modal>
    );
}
