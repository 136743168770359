import {FormControl, FormLabel, Input, Link, Typography} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import Button from "@mui/joy/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDragon} from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import AuthContext from "../contexts/auth-context";
import {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import ApiClient from "../utils/api-client";
import NotificationContext from "../contexts/notification-context";
import LoginForm from "../forms/login-form";

export default function SignInPage() {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const { sendError, clear } = useContext(NotificationContext);
    const error = useState(null);

    const signIn = async function(username, password) {
        try {
            const client = new ApiClient();
            const token = await client.login(username, password);
            authContext.login(token);
            clear();    // We clear the notifications in case of some errors appeared.
            navigate("/");
        }
        catch (e) {
            sendError(e);
        }
    }

    return (
        <>
            <LoginForm onSubmit= {(username, password) => signIn(username, password)}
                       error={error} />
        </>
    );
}
