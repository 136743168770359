import Button from "@mui/joy/Button";
import {Avatar, Box, ButtonGroup, Grid, Link, Skeleton, Stack, Table, Typography} from "@mui/joy";
import RichTextEditor from "../../components/rich-text-editor";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey, faSave} from "@fortawesome/free-solid-svg-icons";
import ApiClient from "../../utils/api-client";
import NotificationContext from "../../contexts/notification-context";
import WikiSummaryFrame from "../../components/wiki-summary-frame";
import DOMPurify from 'dompurify';
import BreadcrumbNavigation from "../../components/breadcrumb-navigation";
import Chip from "@mui/joy/Chip";
import Sheet from "@mui/joy/Sheet";
import apiClient from "../../utils/api-client";
import IconButton from "@mui/joy/IconButton";
import {faChevronLeft, faChevronRight} from "@fortawesome/pro-solid-svg-icons";
import PaginationFrame from "../../components/pagination-frame";

export default function AdminUpcostsFragment() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [numOfPages, setNumOfPages] = useState(1);
    const [page, setPage] = useState(1);
    const { sendError } = useContext(NotificationContext);
    return (
        <>
            <Sheet variant="outlined" sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm' }} >
                <Table
                    aria-labelledby="tableTitle"
                    hoverRow
                    sx={{
                        '--TableCell-headBackground': 'transparent',
                        '--TableCell-selectedBackground': (theme) =>
                            theme.vars.palette.success.softBg,
                        '& thead th:nth-child(1)': {
                            width: '50px',
                        },
                        '& thead th:nth-child(2)': {
                            width: '15%',
                        }
                    }}>
                    <thead>
                    <tr>
                        <th>Service</th>
                        <th>Cost</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                FontAwesome Pro
                            </td>
                            <td>100€ / year</td>
                        </tr>
                        <tr>
                            <td>
                                Domain <code>raidlead.com</code>
                            </td>
                            <td>12.59€ / year</td>
                        </tr>
                        <tr>
                            <td>
                                Email Pro
                            </td>
                            <td>4.99€</td>
                        </tr>
                    </tbody>
                </Table>
            </Sheet>
        </>
    );
}
