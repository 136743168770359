import './app.css';
import {BrowserRouter, Route, Router, Routes, useLocation} from 'react-router-dom';
import {Box, Container, CssVarsProvider, Switch} from "@mui/joy";
import Header from "./ui/components/header";
import HomePage from "./ui/pages/home-page";
import NotificationsHolder from "./ui/components/notifications-holder";
import {NotificationProvider} from "./ui/contexts/notification-context";
import ShowcasedStrategiesListPage from "./ui/pages/showcased-strategies-list-page";
import HelpPage from "./ui/pages/help-page";
import BlogPage from "./ui/pages/blog-page";
import EditStrategyPage from "./ui/pages/edit-strategy-page";
import chakraTheme from "./theme";
import MRTNotePage from "./ui/pages/m-r-t-note-page";
import SettingsPage from "./ui/pages/settings-page";
import SignInPage from "./ui/pages/sign-in-page";
import {AuthProvider} from "./ui/contexts/auth-context";
import MyStrategiesListPage from "./ui/pages/my-strategies-list-page";
import StrategyPage from "./ui/pages/strategy-page";
import Footer from "./ui/components/footer";
import SignUpPage from "./ui/pages/sign-up-page";
import GameConfigPage from "./ui/pages/game-config-page";
import AdminHelpListFragment from "./ui/pages/fragments/admin-help-list-fragment";
import AdminEditHelpFragment from "./ui/pages/fragments/admin-edit-help-fragment";
import AdminPage from "./ui/pages/admin-page";
import WaitListPage from "./ui/pages/wait-list-page";

function App() {
    return (
        <CssVarsProvider theme={chakraTheme} defaultMode="dark">
            <AuthProvider>
                <NotificationProvider>
                    <BrowserRouter>
                        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100dvh" }}>
                            <Header />
                            <Container size="md">
                                <Box
                                    component="main"
                                    className="MainContent"
                                    sx={{
                                        px: {
                                            xs: 2,
                                            md: 3
                                        },
                                        pt: {
                                            md: "calc(30px + var(--Header-height))"
                                        },
                                        pb: {
                                            xs: 2,
                                            sm: 2,
                                            md: 3
                                        },
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        minWidth: 0,
                                        minHeight: "calc(100dvh - var(--Header-height) - 124px)",
                                        gap: 2
                                    }}
                                    style={{ margin: "auto" }}>
                                        <Routes>
                                            <Route path="/" element={<HomePage />} />
                                            <Route path="/mrt-note" element={<MRTNotePage />} />
                                            <Route path="/strategies" element={<ShowcasedStrategiesListPage />} />
                                            <Route path="/strategies/:id" element={<StrategyPage />} />
                                            <Route path="/my-strategies/:id" element={<StrategyPage />} />
                                            <Route path="/strategies/:id/edit" element={<EditStrategyPage />} />
                                            <Route path="/my-strategies/:id/edit" element={<EditStrategyPage />} />
                                            <Route path="/blog" element={<BlogPage />} />
                                            <Route path="/help/:url" element={<HelpPage />} />
                                            <Route path="/help/game-configs/:id" element={<GameConfigPage />} />
                                            <Route path="/settings/*" element={<SettingsPage />} />
                                            <Route path="/my-strategies" element={<MyStrategiesListPage />} />
                                            <Route path="/sign-in" element={<SignInPage />} />
                                            <Route path="/sign-up" element={<SignUpPage />} />
                                            <Route path="/wait-list" element={<WaitListPage />} />
                                            {/* Admin pages */}
                                            <Route path="/admin/*" element={<AdminPage />} />
                                        </Routes>
                                </Box>
                            </Container>
                            <Footer />
                            <NotificationsHolder />
                        </Box>
                    </BrowserRouter>
                </NotificationProvider>
            </AuthProvider>
        </CssVarsProvider>
    );
}

export default App;
