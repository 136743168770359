import {Alert, Divider, FormControl, FormLabel, Input, Stack, Typography} from "@mui/joy";
import WoWIcon from "../components/wow-icon";
import Button from "@mui/joy/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo, faSave} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import ApiClient from "../utils/api-client";

export default function SettingsDefaultSpecForm(props) {
    const [classSpecs, setClassSpecs] = useState([]);

    const handleSaveClicked = function(e) {
        props.onSave(null);
    }

    useEffect(() => {
        async function fetchCurrentGameConfig() {
            const client = new ApiClient();
            const gameConfig = await client.getCurrentGameConfig();
            setClassSpecs(gameConfig.classes);
        }
        fetchCurrentGameConfig();
    }, []);

    return (
        <>
            <Typography level="body-md">When you make a roster importation without any specialization
                information, the system uses a default specialization tree that is near the meta registered for
                the game config used. You can change it here.</Typography>
            <Alert startDecorator={<FontAwesomeIcon icon={faInfo} />} color="neutral"
                   sx={{ marginTop: "20px", marginBottom: "20px" }}>If you want to reset a specialization to defaults, leave the field blank.</Alert>
            <Stack direction="column" gap={2} style={{ marginTop: "10px" }}>
                {Object.entries(classSpecs).map(([key, value], index) => (
                    <React.Fragment key={key}>
                        <FormControl>
                            <FormLabel><WoWIcon iconId={value.iconId} />&nbsp;{key}</FormLabel>
                            <Input placeholder={value.defaultTree} />
                        </FormControl>
                    </React.Fragment>
                ))}
            </Stack>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }}/>
            <Stack direction="row" style={{ marginTop: "20px" }} gap={2} justifyContent="flex-end">
                <Button variant="soft" color="neutral">Cancel</Button>
                <Button variant="solid"
                        color="primary"
                        startDecorator={<FontAwesomeIcon icon={faSave} />}
                        onClick={handleSaveClicked}>Save</Button>
            </Stack>
        </>
    );
}
