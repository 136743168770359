import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {StrategyForm} from "../forms/strategy-form";
import {Breadcrumbs, Link, Stack, Typography} from "@mui/joy";
import Button from "@mui/joy/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faSave} from "@fortawesome/free-solid-svg-icons";
import NotificationContext from "../contexts/notification-context";
import ApiClient from "../utils/api-client";
import Sheet from "@mui/joy/Sheet";
import BreadcrumbNavigation from "../components/breadcrumb-navigation";

export default function EditStrategyPage() {
    let { id } = useParams();
    const navigate = useNavigate();
    const [template, setTemplate] = useState({id: 0, title: '', actions: []});
    const { sendError, sendSuccess } = React.useContext(NotificationContext);

    const fetchTemplate = async function() {
        try {
            const client = new ApiClient();
            const result = await client.getTemplate(id);
            setTemplate(result);
        }
        catch (e) {
            sendError(e);
        }
    }

    const saveTemplate = async function(savedTemplate) {
        try {
            const client = new ApiClient();
            const data = {...savedTemplate};
            if (data.id === "") {
                const result = await client.addTemplate(data);
                setTemplate(result);
            }
            else {
                const result = await client.editTemplate(id, data);
                setTemplate(result);
            }

            sendSuccess("Template saved!");
        } catch (e) {
            sendError(e);
        }
    }

    useEffect(() => {
        if (id !== "new") {
            fetchTemplate();
        }
    }, []);

    return (
        <>
            <BreadcrumbNavigation>
                <Link color="neutral" href={"/strategies/" + template.id}>
                    {template.id}
                </Link>
                <Link color="neutral">
                    Edit
                </Link>
            </BreadcrumbNavigation>

            {id === "new" ? (
                <>
                    <Typography level="h1">Create Template</Typography>
                    <StrategyForm onSave={saveTemplate}
                                  onCancel={(e) => navigate("/strategies")} />
                </>
            ) : (
                <>
                    <Typography level="h1">Edit Template {id}</Typography>
                    <StrategyForm value={template}
                                  onSave={saveTemplate}
                                  onCancel={(e) => navigate("/strategies")} />
                </>
            )}
        </>
    );
}
