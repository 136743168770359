import {Avatar} from "@mui/joy";

export default function RaidLeadAvatar(props) {
    if (props.anonymous) {
        return (
            <Avatar size={props.size ?? "md"}>?</Avatar>
        );
    }
    else {
        return (
            <Avatar size={props.size ?? "md"}></Avatar>
        );
    }

}
