import {Box, Card, Table, Tooltip, Typography} from "@mui/joy";
import {v4 as uuidv4} from "uuid";
import {formatTime} from "../utils/util";
import WoWIcon from "./wow-icon";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightLong} from "@fortawesome/free-solid-svg-icons";

export default function AssignationTable(props) {
    const [assignations, setAssignations] = useState(props.assignations);

    useEffect(() => {
        setAssignations(props.assignations);
    }, [props.assignations]);

    const makeTargetsString = function(targets) {
        const [main, specific] = targets.split(':');
        if (main === "raid") {
            return "Raid";
        }
        else if (main === "self") {
            return "Self";
        }
        else if (main === "solo" || main === "duo") {
            return specific.toUpperCase();
        }
    }

    return (
        <Card style={{ marginTop: "15px", marginBottom: "15px" }}>
            <Table variant="soft" size="sm">
                <tbody>
                {assignations.map((assign, index) => (
                    <tr key={uuidv4()}>
                        <td style={{ width: "50px", textAlign: "center" }}><Typography level="body-sm" color="primary">{formatTime(assign.action.timestamp)}</Typography></td>
                        <td style={{ width: "50px", textAlign: "center" }}><WoWIcon iconId={assign.action.iconId} /></td>
                        <td style={{ width: "300px" }}>{assign.action.description}</td>
                        <td>{assign.abilities.map((ability, index2) => (
                            <React.Fragment key={uuidv4()}>
                                <span style={{ fontWeight: "bold", color: ability.player.color }}>{ability.player.name}</span>&nbsp;
                                <Tooltip variant="outlined" title={ability.ability.name}>
                                    <Box style={{ display: "inline", marginLeft: "5px", marginRight: "10px", position: "relative", top: "5px" }}>
                                        <WoWIcon iconId={ability.ability.iconId} size="sm" />
                                    </Box>
                                </Tooltip>
                            </React.Fragment>
                        ))}
                            {assign.abilities.length > 0 &&
                                <>
                                    <FontAwesomeIcon icon={faArrowRightLong}/>&nbsp;{makeTargetsString(assign.action.targets)}
                                </>
                            }
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </Card>
    );
}
