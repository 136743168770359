import {Divider, Stack, Typography} from "@mui/joy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard, faDisplay, faKey, faSave} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/joy/Button";
import React, {useContext, useState} from "react";
import {faPatreon} from "@fortawesome/free-brands-svg-icons";
import SettingsDisplayForm from "../../forms/settings-display-form";
import ApiClient from "../../utils/api-client";
import NotificationContext from "../../contexts/notification-context";
import SettingsAuthenticationForm from "../../forms/settings-authentication-form";

export default function SettingsAuthenticationFragment(props) {
    const [user, setUser] = useState(props.user);
    const { sendSuccess, sendError } = useContext(NotificationContext);

    const changePassword = async (password, passwordValidation) => {
        try {
            const client = new ApiClient();
            if (password !== passwordValidation) {
                throw new Error("Passwords don't match!");
            }
            await client.changeLoggedUserPassword(password);
            sendSuccess("Password changed!");
        }
        catch (e) {
            sendError(e);
        }
    }

    return (
        <>
            <Typography level="title-lg"><FontAwesomeIcon icon={faKey} />&nbsp;Authentication</Typography>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }}/>
            <SettingsAuthenticationForm settings={props.user.settings}
                                        onSave={(password, passwordValidation) => changePassword(password, passwordValidation)} />
        </>
    );
}
