import logo from "../../logo.svg";
import Button from "@mui/joy/Button";
import Sheet from "@mui/joy/Sheet";
import {
    AspectRatio,
    Box,
    Card,
    CardActions,
    CardContent,
    CardOverflow,
    Divider,
    Grid, Link,
    Stack, Step, StepIndicator, Stepper,
    Typography
} from "@mui/joy";
import RaidFrame from "../components/raid-frame";
import IconButton from "@mui/joy/IconButton";
import {useNavigate} from "react-router-dom";
import ImageGallery from "../components/image-gallery";

export default function HomePage() {
    const navigate = useNavigate();

    return (
        <>
            <Box sx={{ position: 'absolute', left: 0, right: 0, top: "44px" }}>
                <div style={{
                    backgroundImage: "url(bg-dragonflight.jpg)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    border: 0,
                    width: '100%',
                    height: '500px',  opacity: 0.4 }} />
            </Box>
            <Box sx={{ position: 'absolute', left: 0, right: 0, top: "345px", height: '200px', zIndex: 8}}>
                <div style={{
                    height: '100%',
                    background: "linear-gradient(0deg, rgba(12,12,12,1) 0%, rgba(9,9,121,0) 40%, rgba(0,212,255,0) 100%)"
                }} />
            </Box>
            <Grid container spacing={2} sx={{ flexGrow: 1, marginTop: "55px", zIndex: 9 }}>
                <Grid lg={7}>
                    <Stack sx={{ marginTop: "65px" }}>
                        <Typography level="h1" sx={{ textShadow: "2px 2px 5px black" }}>Revolutionize Your Raid Preparation</Typography>
                        <Typography level="title-lg" sx={{ textShadow: "2px 2px 5px black", maxWidth: "450px" }}>Automate your raid setups, heal assignments, and team divisions with real-time, on-the-fly generation.</Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Sheet sx={{ width: '100%', mt: 20, zIndex: 9, background: "transparent" }}>
                <Grid container spacing={2} sx={{ display: 'flex', zIndex: 9, mt: 2 }}>
                    <Grid lg={4} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Card variant="outlined" sx={{ flexGrow: 1 }}>
                            <CardOverflow>
                                <AspectRatio ratio="2">
                                    <img
                                        src="curseforge-0.png"
                                        loading="lazy"
                                        style={{ filter: "blur(4px)", transform: "scale(1.4) rotate(-11deg)" }}
                                        alt=""
                                    />
                                </AspectRatio>
                            </CardOverflow>
                            <CardContent>
                                <Stepper size="lg" orientation="vertical" sx={{ alignItems: "center" }}>
                                    <Step
                                        indicator={
                                            <StepIndicator variant="solid" color="primary">
                                                1
                                            </StepIndicator>
                                        }
                                    ></Step>
                                </Stepper>
                                <Typography level="title-lg" sx={{ textAlign: "center" }}>Download the addon</Typography>
                                <Typography level="body-md">
                                    Begin by downloading and installing <Link href="">RaidLead Assistant</Link>. This setup ensures seamless integration with your game, providing a straightforward way to export your raid's roster.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button variant="outlined" size="lg" color="neutral"
                                        component="a" href="/strategies">
                                    Download
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid lg={4} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Card variant="outlined" sx={{ flexGrow: 1 }}>
                            <CardOverflow>
                                <AspectRatio ratio="2">
                                    <img
                                        src="capture-note-3.png"
                                        style={{ filter: "blur(4px)", transform: "scale(1.4) rotate(4deg)" }}
                                        loading="lazy"
                                        alt=""
                                    />
                                </AspectRatio>
                            </CardOverflow>
                            <CardContent>
                                <Stepper size="lg" orientation="vertical" sx={{ alignItems: "center" }}>
                                    <Step
                                        indicator={
                                            <StepIndicator variant="solid" color="primary">
                                                2
                                            </StepIndicator>
                                        }
                                    ></Step>
                                </Stepper>
                                <Typography level="title-lg" sx={{ textAlign: "center" }}>Find a strategy</Typography>
                                <Typography level="body-md">Browse within our strategies to find the best one that fullfill your wants, or create your own strategy.</Typography>
                            </CardContent>
                            <CardActions>
                                <Button variant="solid" size="lg" color="primary"
                                        component="a" href="/strategies">
                                    View strategies
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid lg={4} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Card variant="outlined" sx={{ flexGrow: 1 }}>
                            <CardOverflow>
                                <AspectRatio ratio="2">
                                    <img
                                        src="image-raid-2.webp"
                                        loading="lazy"
                                        alt=""
                                    />
                                </AspectRatio>
                            </CardOverflow>
                            <CardContent>
                                <Stepper size="lg" orientation="vertical" sx={{ alignItems: "center" }}>
                                    <Step
                                        indicator={
                                            <StepIndicator variant="solid" color="primary">
                                                3
                                            </StepIndicator>
                                        }
                                    ></Step>
                                </Stepper>
                                <Typography level="title-lg" sx={{ textAlign: "center" }}>Get your assignations</Typography>
                                <Typography level="body-md">Click on "Apply this strategy" to generate your raid assignments automatically. Additional configuration allows you to fine tune the results.</Typography>
                            </CardContent>
                            <CardActions >
                                <Button variant="outlined" size="lg" color="neutral"
                                        component="a" href="/help/overview">
                                    How it works
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Sheet>

            <ImageGallery images={["capture-note-0.png", "capture-note-1.png", "capture-note-2.png", "capture-note-3.png"]} />
            <Typography level="body-md" sx={{ mt: 3, textAlign: 'justify' }}>
                <p>
                    RaidLead is designed to streamline your gaming experience by offering advanced, real-time tools for raid management. Our platform simplifies the preparation and execution of raids, ensuring you can focus on gameplay without the hassle of manual coordination. By doing automatic assignment of roles based on player skills, RaidLead enhances your control and efficiency.
                </p>
                <p>
                    Joining the RaidLead waitlist puts you at the forefront of gaming innovation. You'll be the first to access new features, enjoy enhanced raid management capabilities, and influence future developments with your feedback.
                </p>
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center',
                justifyContent: 'center', mt: 2 }}>
                <Button size="lg" color="primary" component="a" href="/wait-list">Join the wait list</Button>
            </Box>
        </>
    );
}
