import {useEffect, useState} from "react";
import {Input} from "@mui/joy";
import {formatTime} from "../utils/util";

export default function DurationInput(props) {
    const [inputValue, setInputValue] = useState("");
    const [seconds, setSeconds] = useState(props.value);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);  // Update the input value directly
        const regex = /^([0-5]?[0-9]):([0-5]?[0-9])$/;
        if (regex.test(value) && value.length === 5) {
            // Convert MM:SS to seconds if valid and update state
            const [minutes, secs] = value.split(':').map(Number);
            const computedSeconds = (minutes * 60) + secs
            setSeconds(computedSeconds);
            if (props.onChange) {
                props.onChange({target: { value: computedSeconds}});
            }
        }
    };

    useEffect(() => {
        setInputValue(formatTime(props.value));
    }, [props.value]);


    return (
        <Input
            type="text"
            value={inputValue}
            variant={props.variant}
            onChange={handleInputChange}
            placeholder="MM:SS"
            inputprops={{ maxLength: 5 }}
        />
    );
}
