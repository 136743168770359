import {FormControl, FormLabel, Input, Link, Typography} from "@mui/joy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDragon} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/joy/Button";
import Sheet from "@mui/joy/Sheet";
import * as React from "react";
import {useEffect, useState} from "react";

export default function LoginForm(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const handleLoginClicked = function(e) {
        setLoading(true);
        props.onSubmit(username, password);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLoginClicked(username, password);
        }
    };

    useEffect(() => {
        setLoading(false);
    }, [props.error]);

    return (
        <Sheet
            sx={{
                width: 300,
                mx: 'auto', // margin left & right
                my: 4, // margin top & bottom
                py: 3, // padding top & bottom
                px: 2, // padding left & right
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: 'sm',
                boxShadow: 'md',
            }}
            style={{ marginTop: "150px" }}
            variant="outlined"
        >
            <div>
                <Typography level="h1" style={{ textAlign: "center" }}><FontAwesomeIcon icon={faDragon} /> RaidLead</Typography>
                <Typography level="body-md" style={{ textAlign: "center" }}>Sign in to continue.</Typography>
            </div>
            <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                    // html input attribute
                    name="email"
                    type="email"
                    placeholder="johndoe@email.com"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
            </FormControl>
            <FormControl>
                <FormLabel>Password</FormLabel>
                <Input
                    // html input attribute
                    name="password"
                    type="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
            </FormControl>
            <Button loading={loading} sx={{ mt: 1 /* margin top */ }} onClick={handleLoginClicked}>Log in</Button>
            <Typography
                endDecorator={<Link href="/wait-list">Join the Waitlist</Link>}
                fontSize="sm"
                sx={{ alignSelf: 'center' }}
            >
                Don&apos;t have an account?
            </Typography>
        </Sheet>
    );
}
