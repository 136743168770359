import {Box, Container, Grid, Input, Link, Typography} from "@mui/joy";
import React from "react";
import Sheet from "@mui/joy/Sheet";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopyright} from "@fortawesome/pro-solid-svg-icons";
import {useLocation} from "react-router-dom";

export default function Footer() {
    const location = useLocation();
    const hideHeaderPaths = ['/sign-in','/sign-up','/wait-list'];

    return (
        <>
            {!hideHeaderPaths.includes(location.pathname) &&
                <Sheet
                    sx={{
                        display: 'flex',
                        bgcolor: '#111',
                        borderColor: 'background.level2',
                        width: '100%',
                        height: '50px',
                        zIndex: 9995,
                        gap: 1,
                        pt: '10px',
                        pb: '10px',
                    }}>
                    <Container size="md" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography level="body-sm"
                                    sx={{ textAlign: "center" }}
                                    startDecorator={<FontAwesomeIcon icon={faCopyright} />}>
                            RaidLead 2024-{new Date().getFullYear()} • Contact us at:&nbsp;<Link href="mailto:contact@raidlead.com">contact@raidlead.com</Link>
                        </Typography>
                    </Container>
                </Sheet>
            }
        </>
    );
}
