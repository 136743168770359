import {Card, Typography} from "@mui/joy";
import {getColorForClassSpec, getClassName} from "../utils/util";


export default function PlayerFrame({ player }) {
    let colorCodes = {
        "deathKnight": "#C41E3A",
        "demonHunter": "#A330C9",
        "druid": "#FF7C0A",
        "evoker": "#33937F",
        "hunter": "#AAD372",
        "mage": "#3FC7EB",
        "monk": "#00FF98",
        "paladin": "#F48CBA",
        "priest": "#FFFFFF",
        "rogue": "#FFF468",
        "shaman": "#0070DD",
        "warlock": "#8788EE",
        "warrior": "#C69B6D",
    }

    const classColor = getColorForClassSpec(player.class);

    return (
        <Card sx={{ padding: "5px", borderRadius: "4px", gap: 0, backgroundColor: classColor, border: "0" }}>
            <Typography fontSize="sm" sx={{ fontWeight: "bold", height: "24px", whiteSpace: "nowrap", color: "black", overflow: "hidden", textOverflow: "ellipsis" }}>
                {player.name}
            </Typography>
            <Typography fontSize="xs" sx={{ fontStyle: "italic", color: "black" }}>
                {getClassName(player.class)}
            </Typography>
        </Card>
    );
}
