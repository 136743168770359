import {Divider, Stack, Typography} from "@mui/joy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard, faDisplay, faSave, faTree} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/joy/Button";
import React, {useContext, useState} from "react";
import ApiClient from "../../utils/api-client";
import NotificationContext from "../../contexts/notification-context";
import SettingsDefaultSpecForm from "../../forms/settings-default-spec-form";

export default function SettingsDefaultsFragment(props) {
    const [user, setUser] = useState(props.user);
    const { sendSuccess, sendError } = useContext(NotificationContext);

    const saveUserSettings = async(settings) => {
        try {
            const client = new ApiClient();
            const data = {...user};
            data.settings = settings;
            const result = await client.editLoggedUser(data);
            setUser(result);
            sendSuccess("Defaults updated!");
            props.onReload();
        }
        catch (e) {
            sendError(e);
        }
    }

    return (
        <>
            <Typography level="title-lg"><FontAwesomeIcon icon={faTree} />&nbsp;Defaults specializations</Typography>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }}/>
            <SettingsDefaultSpecForm settings={props.user.settings} onSave={(settings) => saveUserSettings(settings)} />
        </>
    );
}
