const colorCodes = {
    "deathKnight": "#C41E3A",
    "demonHunter": "#A330C9",
    "druid": "#FF7C0A",
    "evoker": "#33937F",
    "hunter": "#AAD372",
    "mage": "#3FC7EB",
    "monk": "#00FF98",
    "paladin": "#F48CBA",
    "priest": "#FFFFFF",
    "rogue": "#FFF468",
    "shaman": "#0070DD",
    "warlock": "#8788EE",
    "warrior": "#C69B6D",
}

const getColorForClassSpec = function(classSpec) {
    let color = colorCodes[getClassId(classSpec)];
    return color;
}

const getTextColorForClassSpec = function(classSpec) {
    if (classSpec.startsWith("priest") || classSpec.startsWith("rogue")) {
        return "#000";
    }
    else {
        return "#fff";
    }
}

const getClassName = function(classSpec) {
    const classId = getClassId(classSpec);
    const className = classId.charAt(0).toUpperCase() + classId.slice(1);
    return className;
}

const getClassId = function(classSpec) {
    const classId = classSpec.split('-')[0];
    return classId;
}

const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60).toString().padStart(2, '0');
    const secs = (totalSeconds % 60).toString().padStart(2, '0');
    return `${minutes}:${secs}`;
};

const formatSpellName = (name, variant) => {
    if (variant == null || variant === "") {
        return name;
    }
    else {
        return name + " (" + variant + ")";
    }
}

const formatCategory = function(value) {
    switch (value) {
        case "adh":
            return "Amirdrassil, the Dream's Hope";
        case "voti":
            return "Vault of the Incarnates";
        case "asc":
            return "Aberrus, the Shadowed Crucible";
        case "ext":
            return "Exterior";
        case "misc":
        default:
            return "Miscellaneous";
    }
}

export {
    getColorForClassSpec,
    getTextColorForClassSpec,
    getClassName,
    formatTime,
    formatSpellName,
    formatCategory
};
