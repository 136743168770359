import logo from "../../logo.svg";
import Button from "@mui/joy/Button";
import Sheet from "@mui/joy/Sheet";
import {
    Alert, Checkbox,
    Divider,
    FormControl, FormHelperText,
    FormLabel,
    Grid, Input,
    ListItemButton,
    ListItemContent,
    ListItemDecorator, Stack,
    Typography
} from "@mui/joy";
import RaidFrame from "../components/raid-frame";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleRight,
    faBank,
    faCreditCard,
    faDisplay,
    faInfo,
    faKey,
    faSave, faTree,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import React, {useContext, useEffect, useState} from "react";
import ApiClient from "../utils/api-client";
import NotificationContext from "../contexts/notification-context";
import ListDivider from "@mui/joy/ListDivider";
import WoWIcon from "../components/wow-icon";
import SettingsDefaultSpecForm from "../forms/settings-default-spec-form";
import SettingsDisplayForm from "../forms/settings-display-form";
import SettingsAuthenticationForm from "../forms/settings-authentication-form";
import AdminHelpListFragment from "./fragments/admin-help-list-fragment";
import {faBan, faBook, faDashboard, faDollar, faGrid, faHandsHelping, faUsers} from "@fortawesome/pro-solid-svg-icons";
import BreadcrumbNavigation from "../components/breadcrumb-navigation";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import HomePage from "./home-page";
import AdminEditHelpFragment from "./fragments/admin-edit-help-fragment";
import AdminUsersListFragment from "./fragments/admin-users-list-fragment";
import AdminEditUserFragment from "./fragments/admin-edit-user-fragment";
import AdminUpcostsFragment from "./fragments/admin-upcosts-fragment";

export default function AdminPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const { sendSuccess, sendError } = useContext(NotificationContext);

    return (
        <>
            <Typography level="h1">Admin</Typography>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid sm={3}>
                    <List>
                        <ListItem >
                            <ListItemButton
                                            href="/admin/dashboard"
                                            color="neutral"
                                            selected={(location.pathname === '/admin/dashboard')}
                                            onClick={(e) => navigate("/admin/dashboard")}>
                                <ListItemDecorator><FontAwesomeIcon icon={faDashboard} /></ListItemDecorator>
                                <ListItemContent>Dashboard</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem >
                            <ListItemButton
                                            href="/admin/users"
                                            color="neutral"
                                            selected={(location.pathname === '/admin/users')}
                                            onClick={(e) => navigate("/admin/users")}>
                                <ListItemDecorator><FontAwesomeIcon icon={faUsers} /></ListItemDecorator>
                                <ListItemContent>Users</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                            href="/admin/documentation"
                                            color="neutral"
                                            selected={(location.pathname === '/admin/documentation')}
                                            onClick={(e) => navigate("/admin/documentation")}>
                                <ListItemDecorator><FontAwesomeIcon icon={faBook} /></ListItemDecorator>
                                <ListItemContent>Documentation</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                            href="/admin/strategies"
                                            color="neutral"
                                            selected={(location.pathname === '/admin/strategies')}
                                            onClick={(e) => navigate("/admin/strategies")}>
                                <ListItemDecorator><FontAwesomeIcon icon={faGrid} /></ListItemDecorator>
                                <ListItemContent>Strategies</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                            href="/admin/moderation"
                                            color="neutral"
                                            selected={(location.pathname === '/admin/moderation')}
                                            onClick={(e) => navigate("/admin/moderation")}>
                                <ListItemDecorator><FontAwesomeIcon icon={faBan} /></ListItemDecorator>
                                <ListItemContent>Moderation</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem>
                            <ListItemButton
                                href="/admin/upcosts"
                                color="neutral"
                                selected={(location.pathname === '/admin/upcosts')}
                                onClick={(e) => navigate("/admin/upcosts")}>
                                <ListItemDecorator><FontAwesomeIcon icon={faDollar} /></ListItemDecorator>
                                <ListItemContent>Upcosts</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Grid>
                <Grid sm={9}>
                    <Sheet style={{ paddingLeft: "30px", marginTop: "10px" }}>
                        <Routes>
                            <Route path="/users" element={<AdminUsersListFragment />} />
                            <Route path="/users/:id" element={<AdminEditUserFragment />} />
                            <Route path="/documentation" element={<AdminHelpListFragment />} />
                            <Route path="/documentation/:url" element={<AdminEditHelpFragment />} />
                            <Route path="/upcosts" element={<AdminUpcostsFragment />} />
                        </Routes>
                    </Sheet>
                </Grid>
            </Grid>

        </>
    );
}

