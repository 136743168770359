import Button from "@mui/joy/Button";
import {Avatar, Box, ButtonGroup, Grid, Link, Skeleton, Stack, Table, Typography} from "@mui/joy";
import RichTextEditor from "../../components/rich-text-editor";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey, faSave} from "@fortawesome/free-solid-svg-icons";
import ApiClient from "../../utils/api-client";
import NotificationContext from "../../contexts/notification-context";
import WikiSummaryFrame from "../../components/wiki-summary-frame";
import DOMPurify from 'dompurify';
import BreadcrumbNavigation from "../../components/breadcrumb-navigation";
import Chip from "@mui/joy/Chip";
import Sheet from "@mui/joy/Sheet";
import apiClient from "../../utils/api-client";
import IconButton from "@mui/joy/IconButton";
import {faChevronLeft, faChevronRight} from "@fortawesome/pro-solid-svg-icons";
import PaginationFrame from "../../components/pagination-frame";
import RaidLeadAvatar from "../../components/raidlead-avatar";

export default function AdminUsersListFragment() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [numOfPages, setNumOfPages] = useState(1);
    const [page, setPage] = useState(1);
    const { sendError } = useContext(NotificationContext);

    const fetchUsers = async () => {
        try {
            const client = new ApiClient();
            const paginatedUsers = await client.getUsers(page);
            setNumOfPages(paginatedUsers.pages);
            setUsers(paginatedUsers.users);
        }
        catch (e) {
            sendError(e);
        }
    }

    const handlePageChanged = async (newPage) => {
        setPage(newPage);
    }

    useEffect(() => {
        fetchUsers();
    }, [page]);

    return (
        <>
            <Sheet variant="outlined" sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm' }} >
                <Table
                    aria-labelledby="tableTitle"
                    hoverRow
                    sx={{
                        '--TableCell-headBackground': 'transparent',
                        '--TableCell-selectedBackground': (theme) =>
                            theme.vars.palette.success.softBg,
                        '& thead th:nth-child(1)': {
                            width: '50px',
                        },
                        '& thead th:nth-child(2)': {
                            width: '15%',
                        }
                    }}>
                    <thead>
                    <tr>
                        <th></th>
                        <th>Pseudo</th>
                        <th>Email</th>
                        <th>Roles</th>
                    </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <React.Fragment key={user.id}>
                                <tr style={{ cursor: "pointer" }} onClick={() => navigate("/admin/users/" + user.id)}>
                                    <td>
                                        <RaidLeadAvatar size="sm" anonymous={user.roles.includes("waitlist")} />
                                    </td>
                                    <td>{user.roles.includes("waitlist") ? "" : user.pseudo}
                                    </td>
                                    <td>{user.email}</td>
                                    <td>
                                        {user.roles.map((role, index) => (
                                            <Chip key={user.id + "-" + index} size="sm" color="primary" sx={{ mr: 1 }}>{role}</Chip>
                                        ))}
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            </Sheet>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <PaginationFrame page={page} min={1} max={numOfPages} onPageChanged={(e) => handlePageChanged(e)} />
            </Box>

        </>
    );
}
