import React, {createContext, useEffect, useState} from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext({
    login: () => {},
    logout: () => {}
});

const checkLocalStorage = () => {
    const token = localStorage.getItem("jwt");
    return token !== null;
}

const checkUserInfo = () => {
    if (checkLocalStorage() === false) {
        return null;
    }
    const token = localStorage.getItem("jwt");
    const decodedToken = jwtDecode(token);
    const user = {
        id: decodedToken["sub"],
        pseudo: decodedToken["username"],
        roles: decodedToken["roles"]
    };
    return user;
}

const AuthProvider = (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(checkLocalStorage());
    const [user, setUser] = useState(checkUserInfo());

    const login = (token) => {
        localStorage.setItem("jwt", token);
        setIsAuthenticated(true);
        setUser(checkUserInfo());
    }
    const logout = () => {
        setIsAuthenticated(false);
        setUser({});
        localStorage.removeItem("jwt");
        window.location.href = "/sign-in";
    };

    // You would set isAuthenticated based on your auth logic
    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, user, setUser, login, logout }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export { AuthProvider };
export default AuthContext;
