const getParams = function(token) {
    let headers = {
        "Content-Type": "application/json"
    };
    if (token !== null) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    return {
        headers
    };
}

const postParams = function(token, data) {
    let headers = {
        "Content-Type": "application/json"
    };
    if (token !== null) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    return {
        method: 'POST',
        headers,
        mode: "cors",
        body: JSON.stringify(data)
    };
}

class ApiClient {

    jwtToken;

    constructor() {
        const token = localStorage.getItem("jwt");
        if (token != null && token !== "") {
            this.jwtToken = token;
        }
    }

    login = async (username, password) => {
        let data = {
            username,
            password
        };
        const response = await fetch('/api/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            mode: "cors",
            body: JSON.stringify(data)
        });
        if (!response.ok) {
            throw new Error(await response.text());
        }
        const fetchedData = (await response.json()).data;
        this.jwtToken = fetchedData.token;
        return this.jwtToken;
    }

    getLoggedUser = async () => {
        const response = await fetch('/api/users/me', getParams(this.jwtToken));
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        const user = (await response.json()).data;
        if (!("settings" in user)) {
            user.settings = {};
        }
        return user;
    }

    editLoggedUser = async (data) => {
        const response = await fetch('/api/users/me', postParams(this.jwtToken, data));
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }

    changeLoggedUserPassword = async (password) => {
        const data = { password };
        const response = await fetch('/api/users/me/password', postParams(this.jwtToken, data));
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        await (await response.json()).data
    }

    getTemplates = async () => {
        const response = await fetch('/api/strategies', getParams(this.jwtToken));
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }

    getTemplate = async (id) => {
        const response = await fetch('/api/strategies/' + id, getParams(this.jwtToken));
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }

    addTemplate = async(data) => {
        const response = await fetch("/api/strategies/", postParams(this.jwtToken, data));
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }

    editTemplate = async (id, data) => {
        const response = await fetch("/api/strategies/" + id, postParams(this.jwtToken, data));
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data;
    }


    buildMRTNote = async (params) => {
        const response = await fetch("/api/services/mrt-note", postParams(this.jwtToken, params));
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }

    summarizeRoster = async(params) => {
        const response = await fetch("/api/services/roster", postParams(this.jwtToken, params));
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }

    getMyTemplates = async () => {
        const response = await fetch('/api/users/me/strategies/', getParams(this.jwtToken));
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }

    findAbilities = async(gameConfigName, targets, selectableTags) => {
        const response = await fetch(`/api/game-configs/${gameConfigName}/search/abilities?targets=${targets}&selectors=${selectableTags}`);
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }


    findTags = async(gameConfigName, mode) => {
        const response = await fetch(`/api/game-configs/${gameConfigName}/tags?mode=${mode}`);
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }

    getIconInfo = async(value) => {
        const response = await fetch("/api/game-data/icons/" + value);
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }

    getGameConfigs = async() => {
        const response = await fetch("/api/game-configs");
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }

    getGameConfig = async(name) => {
        const response = await fetch(`/api/game-configs/${name}`);
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }

    getCurrentGameConfig = async() => {
        const gameConfigs = await this.getGameConfigs();
        return await this.getGameConfig(gameConfigs[0].name);
    }

    getHelpPage = async(url) => {
        const response = await fetch("/api/docs/" + url);
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }

    setHelpPage = async(url, data) => {
        const response = await fetch("/api/docs/" + url, postParams(this.jwtToken, data));
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }

    getUsers = async (page = 1) => {
        const response = await fetch("/api/users/?page=" + page, getParams(this.jwtToken));
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }

    getUser = async (id) => {
        const response = await fetch("/api/users/" + id, getParams(this.jwtToken));
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
        return (await response.json()).data
    }

    register = async (data, onWaitList) => {
        let url = "/api/users";
        if (onWaitList) {
            url += "?waitlist=true";
        }
        const response = await fetch(url, postParams(this.jwtToken, data));
        if (!response.ok) {
            throw new Error((await response.json()).message);
        }
    }
}

export default ApiClient;
