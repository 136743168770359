import React from 'react';
export default function CodeWithLineBreaks(props) {
    const textWithBreaks = props.text.split('\n').map((text, index) => (
        <React.Fragment key={index}>
            {text}
            <br />
        </React.Fragment>
    ));

    return <code>{textWithBreaks}</code>;
}
