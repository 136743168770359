import logo from "../../logo.svg";
import Button from "@mui/joy/Button";
import Sheet from "@mui/joy/Sheet";
import {Grid} from "@mui/joy";
import RaidFrame from "../components/raid-frame";

export default function BlogPage() {
    return (
        <>
            Blog Page
        </>
    );
}
