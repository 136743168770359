import * as React from 'react';
import Select from '@mui/joy/Select';
import Option, { optionClasses } from '@mui/joy/Option';
import Chip from '@mui/joy/Chip';
import List from '@mui/joy/List';
import ListItemDecorator, {
    listItemDecoratorClasses,
} from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import Typography from '@mui/joy/Typography';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {useEffect} from "react";

export default function SelectTarget(props) {
    const groupNames = {
        raid: ['Entire raid'],
        party: ['Party 1', 'Party 2', 'Party 3', 'Party 4', 'Party 5'],
        duo: ['MT1', 'MT2', 'Orange', 'Moon', 'Star', 'Square', 'Cross'],
        solo: ['MT1', 'MT2', 'Orange', 'Moon', 'Star', 'Square', 'Cross'],
        self: ['Self'],
        ennemy: ['Ennemies', 'Orange', 'Moon', 'Star', 'Square', 'Cross']
    };
    const groupValues = {
        raid: ['raid'],
        party: ['party1', 'party2', 'party3', 'party4', 'party5'],
        duo: ['mt1', 'mt2', 'orange', 'moon', 'star', 'square', 'cross'],
        solo: ['mt1', 'mt2', 'orange', 'moon', 'star', 'square', 'cross'],
        self: ['self'],
        ennemy: ['ennemies', 'orange', 'moon', 'star', 'square', 'cross']
    };
    const colors = {
        raid: 'primary',
        party: 'primary',
        duo: 'primary',
        solo: 'primary',
        self: 'primary',
        ennemy: 'primary'
    };

    function handleChange(e, newValue) {
        const validEntries = newValue.filter(entry => entry !== '');
        const result = (validEntries.length > 0 ? validEntries[0] : null);
        if (props.onChange != null) {
            props.onChange(e, result);
        }
    }

    function renderValue(options) {
        if (!options) {
            return null;
        }

        return (
            <React.Fragment>
                {options.map((option, index) => (
                    <React.Fragment key={"OL" + index}>
                        &nbsp;{option.label}
                        {index < options.length-1 &&
                            " + "
                        }
                    </React.Fragment>
                ))}
            </React.Fragment>
        );
    }

    return (
        <Select
            placeholder="Choose the target"
            multiple
            renderValue={renderValue}
            slotProps={{
                listbox: {
                    component: 'div',
                    sx: {
                        maxHeight: 240,
                        overflow: 'auto',
                        '--List-padding': '0px',
                        '--ListItem-radius': '0px',
                    },
                },
            }}
            value={(props.value != null) ? props.value.split(',') : []}
            onChange={handleChange}
        >
            {Object.entries(groupNames).map(([groupName, values], index) => (
                <React.Fragment key={groupName}>
                    {index !== 0 && <ListDivider role="none" />}
                    <List
                        aria-labelledby={`select-group-${groupName}`}
                        sx={{ '--ListItemDecorator-size': '28px' }}
                    >
                        <ListItem id={`select-group-${groupName}`} sticky>
                            <Typography level="body-xs" textTransform="uppercase">
                                {groupName.toLocaleUpperCase()} ({values.length})
                            </Typography>
                        </ListItem>
                        {values.map((anim, index2) => (
                            <Option
                                key={`${groupName}:${groupValues[groupName][index2]}`}
                                value={`${groupName}:${groupValues[groupName][index2]}`}
                                label={
                                    <React.Fragment>
                                        <Chip
                                            size="sm"
                                            color={colors[groupName]}
                                            sx={{ borderRadius: 'xs', mr: 1 }}
                                        >
                                            {groupName.charAt(0).toUpperCase() + groupName.slice(1)}
                                        </Chip>{' '}
                                        {anim}
                                    </React.Fragment>
                                }
                                sx={{
                                    [`&.${optionClasses.selected} .${listItemDecoratorClasses.root}`]:
                                        {
                                            opacity: 1,
                                        },
                                }}
                            >
                                <ListItemDecorator sx={{ opacity: 0 }}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </ListItemDecorator>
                                {anim}
                            </Option>
                        ))}
                    </List>
                </React.Fragment>
            ))}
        </Select>
    );
}
