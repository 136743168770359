import IconButton from "@mui/joy/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/pro-solid-svg-icons";
import Button from "@mui/joy/Button";
import {ButtonGroup} from "@mui/joy";
import React, {useEffect, useState} from "react";

export default function PaginationFrame(props) {
    const [value, setValue] = useState(props.page ?? 0);
    const [min, setMin] = useState(props.min ?? 1);
    const [max, setMax] = useState(props.max ?? 1);
    function buildPagesArray(currentPage, totalPage, numButtons = 5) {
        const pages = [];
        const half = Math.floor(numButtons / 2);
        let start = Math.max(2, currentPage - half);
        let end = Math.min(totalPage - 1, currentPage + half);

        // Toujours inclure la première page
        pages.push(1);

        // Pages centrales
        for (let i = start; i <= end; i++) {
            pages.push(i);
        }

        // Toujours inclure la dernière page
        if (totalPage > 1) {
            pages.push(totalPage);
        }

        return pages;
    }

    const handlePreviousClicked = () => {
        let newValue = value - 1;
        newValue = Math.max(newValue, min);
        setValue(newValue);
        if (props.onPageChanged) {
            props.onPageChanged(newValue);
        }
    }
    const handleNextClicked = () => {
        let newValue = value + 1;
        newValue = Math.min(newValue, max);
        setValue(newValue);
        if (props.onPageChanged) {
            props.onPageChanged(newValue);
        }
    }
    const handleClicked = (page) => {
        setValue(page);
        if (props.onPageChanged) {
            props.onPageChanged(page);
        }
    }

    useEffect(() => {
        setMin(props.min);
        setMax(props.max);
    }, [props.min, props.max]);

    return (
        <ButtonGroup variant="soft" color="primary">
            <IconButton onClick={handlePreviousClicked} disabled={(value <= min)}><FontAwesomeIcon size="sm" icon={faChevronLeft} /></IconButton>
            {buildPagesArray(value, max).map((page, index) => (
                <Button key={"page-" + page} disabled={value === page} onClick={() => handleClicked(page)}>
                    {page}
                </Button>
            ))}
            <IconButton onClick={handleNextClicked} disabled={(value >= max)}><FontAwesomeIcon size="sm" icon={faChevronRight} /></IconButton>
        </ButtonGroup>
    );
}
