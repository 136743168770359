import {Card, Divider, Grid, ListItemDecorator, Stack, Typography} from "@mui/joy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCreditCard, faSave} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/joy/Button";
import React, {useContext, useState} from "react";
import {faPatreon} from "@fortawesome/free-brands-svg-icons";
import Chip from "@mui/joy/Chip";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import {faCircle, faCircleCheck, faClose, faCross, faXmark} from "@fortawesome/pro-solid-svg-icons";
import AuthContext from "../../contexts/auth-context";

export default function SettingsPatreonFragment() {
    const { user } = useContext(AuthContext);
    const [patreonEnabled, setPatreonEnabled] = useState(user.roles.includes("patreon") ?? false);

    return (
        <>
            <Typography level="title-lg"><FontAwesomeIcon icon={faPatreon} />&nbsp;Patreon</Typography>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }}/>
            <Stack gap={2} sx={{ display: 'flex ', alignItems: 'center' }}>
                <Typography level="body-sm">
                    Join our Patreon community and unlock exclusive benefits designed just for you!
                    As a patron, you'll gain early access to new features, enjoy faster processing speeds,
                    and have more options when building your raid assignments. Your support helps us
                    continue improving and expanding our services. Sign up today to enhance your
                    experience and take your gameplay to the next level!</Typography>
            </Stack>
            <Grid container spacing={1} sx={{ mt: 2 }}>
                <Grid xs={12} sm={12} md={6}>
                    <Card size="lg"
                          variant={patreonEnabled ? "soft" : "solid"}
                          color={patreonEnabled ? "neutral" : "primary"}
                          invertedColors={!patreonEnabled}>
                        <Typography level="h4">
                            Basic
                            {patreonEnabled === false &&
                                <Chip size="sm" variant="soft" color="primary" sx={{ ml: 2}}>ACTIVE</Chip>
                            }
                        </Typography>
                        <Divider inset="none" />
                        <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
                            <ListItem sx={{ color: (patreonEnabled ? 'gray' : 'white'), fontWeight: 'bold' }}>
                                <ListItemDecorator>
                                    <FontAwesomeIcon icon={(patreonEnabled ? faCircle : faCircleCheck)} />
                                </ListItemDecorator>
                                1 iteration per raid assignment
                            </ListItem>
                            <ListItem sx={{ color: (patreonEnabled ? 'gray' : 'white'), fontWeight: 'bold' }}>
                                <ListItemDecorator>
                                    <FontAwesomeIcon icon={(patreonEnabled ? faCircle : faCircleCheck)} />
                                </ListItemDecorator>
                                Showcased templates only
                            </ListItem>
                            <ListItem sx={{ color: (patreonEnabled ? 'gray' : 'white'), fontWeight: 'bold' }}>
                                <ListItemDecorator>
                                    <FontAwesomeIcon icon={(patreonEnabled ? faCircle : faCircleCheck)} />
                                </ListItemDecorator>
                                Stable features access
                            </ListItem>
                            <ListItem sx={{ color: (patreonEnabled ? 'gray' : 'white'), fontWeight: 'bold' }}>
                                <ListItemDecorator>
                                    <FontAwesomeIcon icon={(patreonEnabled ? faCircle : faCircleCheck)} />
                                </ListItemDecorator>
                                Basic support
                            </ListItem>
                        </List>
                    </Card>
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                    <Card size="lg"
                          variant={patreonEnabled ? "solid" : "soft"}
                          color={patreonEnabled ? "primary" : "neutral"}
                          invertedColors={patreonEnabled}>
                        <Typography level="h4">
                            Patreon
                            {patreonEnabled === true &&
                                <Chip size="sm" variant="soft" color="primary" sx={{ ml: 2}}>ACTIVE</Chip>
                            }
                        </Typography>
                        <Divider inset="none" />
                        <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
                            <ListItem sx={{ color: (patreonEnabled ? 'white' : 'gray'), fontWeight: 'bold' }}>
                                <ListItemDecorator>
                                    <FontAwesomeIcon icon={(patreonEnabled ? faCircleCheck : faCircle)} />
                                </ListItemDecorator>
                                Up to 10 iterations per raid assignment
                            </ListItem>
                            <ListItem sx={{ color: (patreonEnabled ? 'white' : 'gray'), fontWeight: 'bold' }}>
                                <ListItemDecorator>
                                    <FontAwesomeIcon icon={(patreonEnabled ? faCircleCheck : faCircle)} />
                                </ListItemDecorator>
                                Showcased and custom templates
                            </ListItem>
                            <ListItem sx={{ color: (patreonEnabled ? 'white' : 'gray'), fontWeight: 'bold' }}>
                                <ListItemDecorator>
                                    <FontAwesomeIcon icon={(patreonEnabled ? faCircleCheck : faCircle)} />
                                </ListItemDecorator>
                                Anticipated access to new features
                            </ListItem>
                            <ListItem sx={{ color: (patreonEnabled ? 'white' : 'gray'), fontWeight: 'bold' }}>
                                <ListItemDecorator>
                                    <FontAwesomeIcon icon={(patreonEnabled ? faCircleCheck : faCircle)} />
                                </ListItemDecorator>
                                Prior support
                            </ListItem>
                        </List>
                    </Card>
                </Grid>
            </Grid>
            <Stack gap={2} sx={{ display: 'flex ', alignItems: 'center', mt: 2 }}>
                <Button size="lg"
                        color="success"
                        variant="solid"
                        startDecorator={<FontAwesomeIcon icon={faPatreon} />}
                        sx={{ width: 200 }}>
                    Login to Patreon
                </Button>
            </Stack>
        </>
    );
}
