import {AspectRatio, Box, Grid, Modal, ModalClose, Typography} from "@mui/joy";
import {useEffect, useState} from "react";
import Sheet from "@mui/joy/Sheet";

export default function ImageGallery(props) {
    const [images, setImages] = useState(props.images ?? []);
    const [open, setOpen] = useState(false);
    const [fullscreenImage, setFullscreenImage] = useState(null);

    const displayFullscreenImage = (image) => {
        setOpen(true);
        setFullscreenImage(image);
    }

    useEffect(() => {
        setImages(props.images);
    }, [props.images]);

    return (
        <>
            <Grid container spacing={2} sx={{ flexGrow: 1, marginTop: "50px", zIndex: 9 }}>
                {images.map((image, index) => (
                    <Grid xs={12} sm={12} md={3} key={index}>
                        <AspectRatio ratio="16/9" variant="solid" sx={{ width: '100%', border: '3px solid black', borderRadius: '5px', cursor: 'pointer' }}>
                            <img src={image}
                                 style={{ filter: "blur(1px)", transform: "scale(1.4) rotate(3deg)" }}
                                 onClick={(e) => displayFullscreenImage(image)}
                            />
                        </AspectRatio>
                    </Grid>
                ))}
            </Grid>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                variant="outlined"
                onClose={() => setOpen(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet sx={{ outline: 0, p: 2 }}>
                    <ModalClose variant="plain" color="neutral" sx={{ m: 0 }} />
                    <img src={fullscreenImage} style={{ width: '100%', maxHeight: 600 }} />
                </Sheet>
            </Modal>
        </>
    );
}
