import {useLocation} from "react-router-dom";
import {Breadcrumbs, Link} from "@mui/joy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import Sheet from "@mui/joy/Sheet";
import React from "react";

export default function BreadcrumbNavigation(props) {
    const location = useLocation();

    const extractUrlName = () => {
        if (location.pathname.startsWith("/strategies")) {
            return "Showcased strategies";
        }
        else if (location.pathname.startsWith("/my-strategies")) {
            return "My strategies";
        }
        else if (location.pathname.startsWith("/admin")) {
            return "Admin";
        }
        else if (location.pathname.startsWith("/settings")) {
            return "Settings";
        }
        else {
            return "Help";
        }
    }

    const extractUrlHref = () => {
        if (location.pathname.startsWith("/strategies")) {
            return "/strategies";
        }
        else if (location.pathname.startsWith("/my-strategies")) {
            return "/my-strategies";
        }
        else if (location.pathname.startsWith("/admin")) {
            return "/admin";
        }
        else if (location.pathname.startsWith("/settings")) {
            return "/settings";
        }
        else {
            return "/help";
        }
    }

    return (
        <Sheet variant="plain" color="neutral" sx={{ borderRadius: "5px" }}>
            <Breadcrumbs aria-label="breadcrumbs">
                <FontAwesomeIcon icon={faHome} />
                <Link color="neutral" href={extractUrlHref()}>
                    {extractUrlName()}
                </Link>
                {props.children}
            </Breadcrumbs>
        </Sheet>
    );

}
