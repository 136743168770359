import {Alert, Divider, FormControl, FormLabel, Input, Link, Typography} from "@mui/joy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDragon, faWarning} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/joy/Button";
import Sheet from "@mui/joy/Sheet";
import * as React from "react";
import {useEffect, useState} from "react";

export default function RegisterForm(props) {
    const [email, setEmail] = useState(props.user.email ?? "");
    const [pseudo, setPseudo] = useState("");
    const [password, setPassword] = useState("");
    const [passwordValidation, setPasswordValidation] = useState("");
    const [loading, setLoading] = useState(false);

    const handleRegisterClicked = function(e) {
        setLoading(true);
        props.onSubmit({ email, pseudo, password, passwordValidation });
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleRegisterClicked();
        }
    };

    useEffect(() => {
        setLoading(false);
    }, [props.error]);

    return (
        <Sheet
            sx={{
                width: 300,
                mx: 'auto', // margin left & right
                my: 4, // margin top & bottom
                py: 3, // padding top & bottom
                px: 2, // padding left & right
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: 'sm',
                boxShadow: 'md',
            }}
            style={{ marginTop: "150px" }}
            variant="outlined"
        >
            <div>
                <Typography level="h3" style={{ textAlign: "center" }}><FontAwesomeIcon icon={faDragon} /> RaidLead</Typography>
                <Typography level="body-md" style={{ textAlign: "center" }}>Sign up to continue.</Typography>
            </div>
            <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                    // html input attribute
                    name="email"
                    type="email"
                    placeholder="johndoe@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
            </FormControl>
            <Divider />
            <FormControl>
                <FormLabel>Pseudo</FormLabel>
                <Input
                    // html input attribute
                    name="pseudo"
                    type="pseudo"
                    placeholder="pseudo"
                    value={pseudo}
                    onChange={(e) => setPseudo(e.target.value)}
                />
            </FormControl>
            <FormControl>
                <FormLabel>Password</FormLabel>
                <Input
                    // html input attribute
                    name="password"
                    type="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </FormControl>
            <FormControl>
                <FormLabel>Password Validation</FormLabel>
                <Input
                    // html input attribute
                    name="passwordValidation"
                    type="password"
                    placeholder="password validation"
                    value={passwordValidation}
                    onChange={(e) => setPasswordValidation(e.target.value)}
                />
            </FormControl>
            <Button loading={loading} sx={{ mt: 1 /* margin top */ }} onClick={handleRegisterClicked}>Sign up</Button>
            <Typography
                endDecorator={<Link href="/sign-in">Sign in</Link>}
                fontSize="sm"
                sx={{ alignSelf: 'center' }}
            >
                Don&apos;t have an account?
            </Typography>
        </Sheet>
    );
}
