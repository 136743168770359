import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css" // Tagify CSS
import "./tagify-custom.css"
import {useCallback, useEffect, useState} from "react";

export default function LogicTags(props) {
    const [value, setValue] = useState(props.value);
    const settings = {
        //enforceWhitelist: true,
        dropdown: {
            position: 'text',
            mapValueTo: 'full',
            classname: 'tagify__dropdown--whitelist',
            enabled: 0,
            maxItems: 5
        },
        userInput: true
        /*editTags: {
            keepInvalid: false, // better to auto-remove invalid tags which are in edit-mode (on blur)
        }*/
    };

    useEffect(() => {
        // For somme reasons, the onChange callbacks doesn't keep the fresh values for states.
        // So we dodge the issue by using a useEffect.
        // As we are in a nested component, this is invisible from the exterior.
        if (props.onChange && value != "") {
            props.onChange({target: { value }});
        }
    }, [value]);

    const onChange = useCallback(e =>{
        try {
            let s = "";
            const tags = JSON.parse(e.detail.value);
            for (const tag of tags) {
                s += tag.value + ",";
            }
            s = s.replace(/,\s*$/, "");
            setValue(s);
        } catch (e) {}
    }, [value]);

    return (
        <>
        {props.whitelist.length > 0 &&
            <>
                <Tags
                    settings={settings}
                    whitelist={props.whitelist}
                    style={{ height: '500px' }}
                    value={value}
                    {...props.tagify}
                    onChange={onChange} />
            </>
        }
        </>
    );
};
