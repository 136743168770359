import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import {formatTime, getTextColorForClassSpec} from "../utils/util";

function TimelineChart({ data, maxDuration }) {
    const ref = useRef();
    const containerRef = useRef();
    const [size, setSize] = useState({ width: 600, height: 400 }); // default size

    const uniqueKeys = data.map(d => `${d.player}-${d.spell}`);
    const height = (uniqueKeys.length * 36) + 40; // 40 pixels per entry
    const margin = { top: 0, right: 30, bottom: 20, left: 30 };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            if (entries[0].contentRect.width) {
                setSize({
                    width: entries[0].contentRect.width,
                    height: height
                });
            }
        });
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }
        return () => resizeObserver.disconnect();
    }, [height]);

    useEffect(() => {
        if (size.width <= 0) return; // Avoid rendering before size is initialized
        const svg = d3.select(ref.current);
        svg.selectAll("*").remove(); // Clear SVG contents before redrawing

        // Set up scales
        const xScale = d3.scaleLinear()
            //.domain([0, d3.max(data, d => d3.max(d.uses, use => use.endTime))])
            .domain([0, maxDuration])
            .range([150, size.width - 80]);

        const yScale = d3.scaleBand()
            .domain(uniqueKeys)
            .rangeRound([0, size.height - 40])
            .paddingInner(0.5)  // Lower padding for less space between bands
            .paddingOuter(0); // Reduce outer padding to pull the scale upwards

        const xAxis = d3.axisBottom(xScale)
            .tickFormat(d => formatTime(d)) // Apply your custom format function
            .tickSize(10) // Adjust tick size if needed
            .tickPadding(5); // Adjust space between ticks and labels if needed

        // Add the x-axis
        svg.append("g")
            .attr("transform", `translate(0,${size.height - 30})`)
            .call(xAxis);

        // Draw bars for each use
        data.forEach((item, idx) => {
            const yPosition = yScale(`${item.player}-${item.spell}`);
            item.uses.forEach(use => {
                const computedWidth = Math.min(xScale(use.endTime) - xScale(use.startTime), xScale(maxDuration) - xScale(use.startTime))
                svg.append("rect")
                    .attr("x", xScale(use.startTime))
                    .attr("y", yPosition)
                    .attr("width", computedWidth)
                    .attr("height", yScale.bandwidth())
                    .attr("fill", item.color);
            });

            // Add labels for each use
            item.uses.forEach(use => {
                const computedWidth = Math.min(xScale(use.endTime) - xScale(use.startTime), xScale(maxDuration) - xScale(use.startTime))
                svg.append("text")
                    .attr("x", xScale(use.startTime) + 5)
                    .attr("y", yPosition + yScale.bandwidth() / 2)
                    .attr("dy", "0.35em")
                    .text((!isNaN(use.startTime) && computedWidth > 80) ? `${formatTime(use.startTime)} → ${formatTime(use.endTime)}` : "")
                    .attr("font-size", "13px")
                    .attr("font-weight", "bold")
                    .attr("fill", getTextColorForClassSpec(item.classSpec));
            });
        });

        // After drawing bars/spells, add separator lines
        uniqueKeys.forEach((key, index) => {
            if (index < uniqueKeys.length - 1) { // Don't add a line after the last item
                svg.append("line")
                    .attr("x1", 0)
                    .attr("x2", size.width)
                    .attr("y1", yScale(key) + yScale.bandwidth() + yScale.step() * yScale.padding() / 2)
                    .attr("y2", yScale(key) + yScale.bandwidth() + yScale.step() * yScale.padding() / 2)
                    .attr("stroke", "#444")
                    .attr("stroke-width", 1);
            }
        });

    }, [data, maxDuration, size]); // Redraw chart when data or dimensions change

    return <div ref={containerRef} style={{ width: '100%', marginTop: '4px' }}>
        <svg ref={ref} width={size.width} height={size.height}></svg>
    </div>;
}

export default TimelineChart;
