import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {StrategyForm} from "../forms/strategy-form";
import {
    Alert,
    AspectRatio,
    Box,
    Breadcrumbs, ButtonGroup,
    Card,
    CardContent,
    Divider,
    Grid,
    Link,
    Stack, Tooltip,
    Typography
} from "@mui/joy";
import Button from "@mui/joy/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleRight,
    faBars,
    faCopy,
    faHeart,
    faHome,
    faSave,
    faStar,
    faWarning
} from "@fortawesome/free-solid-svg-icons";
import NotificationContext from "../contexts/notification-context";
import ApiClient from "../utils/api-client";
import {formatCategory} from "../utils/util";
import Sheet from "@mui/joy/Sheet";
import Chip from "@mui/joy/Chip";
import RosterFrame from "../components/roster-frame";
import AssignationFrame from "../components/assignation-frame";
import {faDotCircle, faDownload, faEdit, faThumbsDown, faThumbsUp} from "@fortawesome/pro-solid-svg-icons";
import AuthContext from "../contexts/auth-context";
import BreadcrumbNavigation from "../components/breadcrumb-navigation";

export default function StrategyPage() {
    let { id } = useParams();
    const navigate = useNavigate();
    const [template, setTemplate] = useState({id: 0, title: '', author: {}, items: []});
    const { sendError, sendSuccess } = React.useContext(NotificationContext);
    const [roster, setRoster] = useState({});
    const [result, setResult] = useState({ assignations: [], note: ""});
    const [computing, setComputing] = useState(false);
    const { isAuthenticated, user } = useContext(AuthContext);

    const fetchTemplate = async function() {
        try {
            const client = new ApiClient();
            const result = await client.getTemplate(id);
            setTemplate(result);
        }
        catch (e) {
            sendError(e);
        }
    }

    const buildMRTNote = async () => {
        setComputing(true);
        try {
            const client = new ApiClient();
            const resultOfMRTNote = await client.buildMRTNote({
                roster: roster.raid,
                template: template.id
            });
            setComputing(false);
            setResult(resultOfMRTNote);
            sendSuccess("MRT note built!");
        }
        catch (e) {
            setComputing(false);
            sendError(e);
        }
    }

    useEffect(() => {
        if (id !== "new") {
            fetchTemplate();
        }
    }, []);

    return (
        <>
            <BreadcrumbNavigation>
                <Link color="neutral">
                    {template.id}
                </Link>
            </BreadcrumbNavigation>

            <Card orientation="horizontal" variant="plain" sx={{
                    p: 0,
                    flexWrap: 'wrap',
                    [`& > *`]: {
                        '--stack-point': '500px',
                        minWidth:
                            'clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)',
                    }}}>
                <AspectRatio flex ratio="1" maxHeight={182} sx={{ minWidth: 250 }}>
                    <img src={template.imageUrl} />
                </AspectRatio>
                <CardContent>
                    <Typography level="h1">
                        {template.title}
                        <Chip
                            size="lg"
                            variant="outlined"
                            sx={{ marginLeft: "20px" }}
                            color="neutral">
                            <FontAwesomeIcon icon={faStar} /> FEATURED
                        </Chip>
                        <Stack direction="row"
                               spacing={2}
                               sx={{
                                   position: 'absolute',
                                   zIndex: 2,
                                   right: 0,
                                   top: -5,
                                   transform: 'translateY(50%)',
                                   fontWeight: 'bold',
                                   fontSize: '12px'
                               }}>
                            <Button
                                size="md"
                                component="a"
                                href={"/strategies/" + id + "/download"}
                                variant="outlined"
                                color="neutral"
                                startDecorator={<FontAwesomeIcon icon={faDownload}/>}
                            >Download</Button>
                            {(isAuthenticated && user.id === template.author.id) &&
                                <Button
                                    size="md"
                                    variant="solid"
                                    color="primary"
                                    startDecorator={<FontAwesomeIcon icon={faEdit}/>}
                                    onClick={() => navigate("edit")}
                                >Modifier</Button>
                            }
                        </Stack>

                    </Typography>
                    <Typography level="body-md">
                        By {template.author.pseudo}&nbsp;•&nbsp;
                        {formatCategory(template.category)}
                    </Typography>

                    <Sheet
                        sx={{
                            //bgcolor: 'background.level1',
                            border: '1px solid #444',
                            borderColor: 'background.level2',
                            borderRadius: 'sm',
                            p: 1.5,
                            my: 1.5,
                            display: 'flex',
                            gap: 2,
                            '& > div': { flex: 1 },
                        }}
                    >
                        <div>
                            <Typography level="body-sm" fontWeight="lg" startDecorator={<FontAwesomeIcon icon={faHeart} />}>
                                Uses
                            </Typography>
                            <Typography level="h4" fontWeight="lg">{template.usages}</Typography>
                        </div>
                        <div>
                            <Typography level="body-sm" fontWeight="lg" startDecorator={<FontAwesomeIcon icon={faDownload} />}>
                                Downloads
                                <Tooltip variant="outlined"
                                         title={
                                            <Typography>You can download templates to be used with our<br />RaidLead Assistant addon.</Typography>
                                         }>
                                    <Chip size="sm" sx={{ bgcolor: 'background.level2', ml: 1, cursor: "help" }}>?</Chip>
                                </Tooltip>
                            </Typography>
                            <Typography level="h4" fontWeight="lg">{template.downloads}</Typography>
                        </div>
                        <div>
                            <Typography level="body-sm" fontWeight="lg" startDecorator={<FontAwesomeIcon icon={faCopy} />}>
                                Copies
                            </Typography>
                            <Typography level="h4" fontWeight="lg">{template.copies}</Typography>
                        </div>
                        <div>
                            <Typography level="body-sm" fontWeight="lg" startDecorator={<FontAwesomeIcon icon={faBars} />}>
                                Actions
                            </Typography>
                            <Typography level="h4" fontWeight="lg">{template.items.length}</Typography>
                        </div>
                    </Sheet>
                    <Alert variant="soft"
                           color="warning"
                           sx={{ marginTop: "15px" }} startDecorator={<FontAwesomeIcon size="lg" icon={faWarning} />}>
                        This strategy is adapted for raids of 20 players.
                    </Alert>
                </CardContent>
            </Card>
            <Divider />
            <RosterFrame onChange={(e) => setRoster(e)} onSubmit={(e) => buildMRTNote()} />
            <AssignationFrame sx={{ marginBottom: 5 }} computing={computing} result={result} />
        </>
    );
}
