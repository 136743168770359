import {Divider, FormControl, FormHelperText, FormLabel, Input, Stack, Typography} from "@mui/joy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard, faSave, faUser} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/joy/Button";
import React, {useEffect, useState} from "react";
import SettingsAccountForm from "../../forms/settings-account-form";

export default function SettingsAccountFragment(props) {
    return (
        <>
            <Typography level="title-lg"><FontAwesomeIcon icon={faUser} />&nbsp;Account</Typography>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }}/>
            <SettingsAccountForm user={props.user} />
        </>
    );
}
