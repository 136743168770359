import {Alert, Checkbox, Divider, FormControl, FormHelperText, FormLabel, Input, Stack, Typography} from "@mui/joy";
import WoWIcon from "../components/wow-icon";
import Button from "@mui/joy/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDisplay, faInfo, faSave} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import ApiClient from "../utils/api-client";

export default function SettingsAuthenticationForm(props) {
    const [password, setPassword] = useState("");
    const [passwordValidation, setPasswordValidation] = useState("");

    const handleSaveClicked = function(e) {
        props.onSave(password, passwordValidation);
        setPassword("");
        setPasswordValidation("");
    }

    return (
        <>
            <Stack direction="column" gap={2}>
                <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input type="password"
                           placeholder="Enter a new password here"
                           value={password}
                           onChange={(e) => setPassword(e.target.value)} />
                    <FormHelperText>8 characters minimum.</FormHelperText>
                </FormControl>
                <FormControl>
                    <FormLabel>Validate password</FormLabel>
                    <Input type="password"
                           placeholder=""
                           value={passwordValidation}
                           onChange={(e) => setPasswordValidation(e.target.value)} />
                    <FormHelperText>Re-enter your password again to confirm the changes.</FormHelperText>
                </FormControl>
            </Stack>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }}/>
            <Stack direction="row" style={{ marginTop: "20px" }} gap={2} justifyContent="flex-end">
                <Button variant="soft" color="neutral">Cancel</Button>
                <Button variant="solid"
                        color="primary"
                        startDecorator={<FontAwesomeIcon icon={faSave} />}
                        onClick={handleSaveClicked}>Save</Button>
            </Stack>
        </>
    );
}
