import {
    Alert,
    AspectRatio,
    Box, Breadcrumbs,
    Card,
    CardContent,
    CardCover,
    CardOverflow,
    Divider, Grid,
    Link,
    Stack,
    Table,
    Typography
} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import React, {useContext, useEffect, useState} from "react";
import Button from "@mui/joy/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faClover, faHome, faStar} from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from "react-router-dom";
import Chip from "@mui/joy/Chip";
import ApiClient from "../utils/api-client";
import NotificationContext from "../contexts/notification-context";
import AuthContext from "../contexts/auth-context";
import IconButton from "@mui/joy/IconButton";
import {formatCategory} from "../utils/util";
import BreadcrumbNavigation from "../components/breadcrumb-navigation";
import useDocumentTitle from "../hooks/document-title-hook";

export default function ShowcasedStrategiesListPage() {
    const [templates, setTemplates] = useState([]);
    const navigate = useNavigate();
    const { sendError } = useContext(NotificationContext);
    const { isAuthenticated } = useContext(AuthContext);
    const fetchTemplates = async () => {
        try {
            let client = new ApiClient();
            const result = await client.getTemplates();
            setTemplates(result);
        }
        catch (e) {
            sendError(e);
        }
    }

    useEffect(() => {
        fetchTemplates();

    }, []);

    useDocumentTitle("RaidLead - Showcased Templates");
    return (
        <>
            <BreadcrumbNavigation>

            </BreadcrumbNavigation>

            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Stack>
                    <Typography level="h1">Showcased Strategies</Typography>
                    <Typography level="title-lg">A list of showcased strategies for each bosses</Typography>
                </Stack>
            </Box>

            {["voti","asc","adh","ext","misc"].map((entry, index) => (
               <React.Fragment key={index}>
                   <Typography level="h2">{formatCategory(entry)}</Typography>
                   <Grid container spacing={2}>
                   {templates.filter(item => item.category === entry).map((template) => (
                       <Grid xs={12} sm={6} md={3} key={template.id}>
                           <Card variant="outlined">
                               <CardOverflow>
                                   <AspectRatio ratio="2">
                                       <img
                                           src={template.imageUrl}
                                           loading="lazy"
                                           alt=""
                                       />
                                   </AspectRatio>
                                   <Chip
                                       aria-label="Like minimal photography"
                                       size="md"
                                       variant="outlined"
                                       color="neutral"
                                       sx={{
                                           position: 'absolute',
                                           zIndex: 2,
                                           right: '1rem',
                                           background: 'black',
                                           bottom: 0,
                                           transform: 'translateY(50%)',
                                           fontWeight: 'bold',
                                           fontSize: '12px'
                                       }}
                                   >
                                       <FontAwesomeIcon icon={faStar} /> FEATURED
                                   </Chip>
                               </CardOverflow>
                               <CardContent>
                                   <Typography level="title-lg">
                                       <Link overlay underline="none" sx={{ color: 'text.primary' }} onClick={(e) => navigate("/strategies/" + template.id)}>{template.title}</Link>
                                   </Typography>
                                   <Typography level="body-md">
                                       {formatCategory(template.category)}
                                   </Typography>
                               </CardContent>
                               <CardOverflow variant="soft">
                                   <Divider inset="context" />
                                   <CardContent orientation="horizontal">
                                       <Typography level="body-xs">{template.usages ?? 0} uses</Typography>
                                       <Divider orientation="vertical" />
                                       <Typography level="body-xs">{template.itemsCount} actions</Typography>
                                   </CardContent>
                               </CardOverflow>
                           </Card>
                       </Grid>
                   ))}
                   </Grid>
               </React.Fragment>
            ))}
        </>
    );
}
