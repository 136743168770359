import React, {useEffect, useState} from "react";
import TimelineChart from "./timeline-chart";
import {Box, Grid, Tooltip, Typography} from "@mui/joy";
import ListItem from "@mui/joy/ListItem";
import List from "@mui/joy/List";
import {formatSpellName, getColorForClassSpec} from "../utils/util";
import WoWIcon from "./wow-icon";
import ListDivider from "@mui/joy/ListDivider";

export default function RosterAbilitiesTable(props) {
    const [statistics, setStatistics] = useState(props.statistics);
    const [maxDuration, setMaxDuration] = useState(props.maxDuration);

    useEffect(() => {
        setStatistics(props.statistics);
        setMaxDuration(props.maxDuration);
    }, [props.statistics, props.maxDuration]);

    const buildChartData = function() {
        let data = [];
        for (const [id, value] of Object.entries(statistics)) {
            let entry = {
                uses: [],
                player: value.player.name,
                classSpec: value.player.class,
                color: getColorForClassSpec(value.player.class),
                iconId: value.ability.iconId,
                spell: value.ability.name,
                spellVariant: value.ability.variant,
                percentage: value.percentage
            };
            for (const use of value.uses) {
                const a = {
                    startTime: use,
                    endTime: use + value.ability.cooldown
                }
                entry.uses.push(a);
            }
            data.push(entry);
        }
        return data;
    }

    return (
        <>
            <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                <TimelineChart data={buildChartData()} maxDuration={maxDuration}/>
                <Box sx={{ position: 'absolute', top: "-4px", left: 0, right: 0, bottom: 0, display: 'flex' }}>
                    <List size="sm">
                        {buildChartData().map((entry) => (
                            <React.Fragment key={`${entry.player}-${entry.spell}`}>
                                <ListItem  style={{ height: "36px" }}>
                                    <span style={{ fontWeight: "bold", color: entry.color }}>{entry.player}</span>
                                    <Tooltip variant="outlined" title={formatSpellName(entry.spell,entry.spellVariant)}>
                                        <Box style={{ display: "inline", marginLeft: "5px", marginRight: "10px", position: "relative", top: "5px" }}>
                                            <WoWIcon iconId={entry.iconId} size="sm" />
                                        </Box>
                                    </Tooltip>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
                <Box sx={{ position: 'absolute', top: "-4px", width: '50px', right: 0, bottom: 0 }}>
                    <List size="sm">
                        {buildChartData().map((entry) => (
                            <React.Fragment key={`${entry.player}-${entry.spell}`}>
                                <ListItem  style={{ height: "36px" }}>
                                    <Typography level="body-sm" style={{ color: entry.color}}>
                                        <i>{(entry.percentage*100).toFixed(0)}%</i>
                                    </Typography>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            </Box>
        </>
    );
}
