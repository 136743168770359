import * as React from 'react';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {faBook, faChevronDown, faChevronRight} from "@fortawesome/pro-solid-svg-icons";
import {useEffect, useState} from "react";
import ApiClient from "../utils/api-client";
import {useNavigate} from "react-router-dom";

export default function WikiSummaryFrame(props) {
    const [gameConfigs, setGameConfigs] = useState([]);
    const [open, setOpen] = useState(props.section === "game-configs");

    const fetchGameConfigs = async () => {
        try {
            const client = new ApiClient();
            const result = await client.getGameConfigs();
            setGameConfigs(result);
        }
        catch (e) {

        }
    }

    const checkSelected = (section) => {
        if (props.selected === section) {
            return true;
        }
        return false;
    }
    const checkGCSelected = (section, gameConfig) => {
        return (props.section === section && props.selected === gameConfig.name);
    }

    useEffect(() => {
        fetchGameConfigs();
    }, []);

    return (
        <Box
            sx={{
                width: 320,
                pl: '24px',
            }}
        >
            <List
                size="sm"
                sx={(theme) => ({
                    // Gatsby colors
                    '--joy-palette-primary-plainColor': '#8a4baf',
                    '--joy-palette-neutral-plainHoverBg': 'transparent',
                    '--joy-palette-neutral-plainActiveBg': 'transparent',
                    '--joy-palette-primary-plainHoverBg': 'transparent',
                    '--joy-palette-primary-plainActiveBg': 'transparent',
                    [theme.getColorSchemeSelector('dark')]: {
                        '--joy-palette-text-secondary': '#635e69',
                        '--joy-palette-primary-plainColor': '#d48cff',
                    },
                    '--List-insetStart': '32px',
                    '--ListItem-paddingY': '0px',
                    '--ListItem-paddingRight': '16px',
                    '--ListItem-paddingLeft': '21px',
                    '--ListItem-startActionWidth': '0px',
                    '--ListItem-startActionTranslateX': '-50%',
                    [`& .${listItemButtonClasses.root}`]: {
                        borderLeftColor: 'divider',
                    },
                    [`& .${listItemButtonClasses.root}.${listItemButtonClasses.selected}`]: {
                        borderLeftColor: 'currentColor',
                        color: '#008080'
                    },
                    '& [class*="startAction"]': {
                        color: 'var(--joy-palette-text-tertiary)',
                    },
                })}
            >
                <ListItem nested>
                    <ListItem component="div" startAction={<FontAwesomeIcon icon={faBook} />}>
                        <Typography level="body-xs" sx={{ textTransform: 'uppercase' }}>
                            Documentation
                        </Typography>
                    </ListItem>
                    <List sx={{ '--List-gap': '0px' }}>
                        <ListItem>
                            <ListItemButton selected={checkSelected("overview")} component="a" href="/help/overview">Overview</ListItemButton>
                        </ListItem>
                    </List>
                </ListItem>
                <ListItem sx={{ '--List-gap': '0px' }}>
                    <ListItemButton selected={checkSelected("templates")} component="a" href="/help/templates">Templates</ListItemButton>
                </ListItem>
                <ListItem sx={{ '--List-gap': '0px' }}>
                    <ListItemButton selected={checkSelected("selectabletags")} component="a" href="/help/selectabletags">Selectable tags</ListItemButton>
                </ListItem>
                <ListItem
                    nested
                    sx={{ my: 1 }}
                    startAction={
                        <IconButton
                            variant="plain"
                            size="sm"
                            color="neutral"
                            onClick={() => setOpen(!open)}
                        >
                            <FontAwesomeIcon icon={faChevronDown}
                                sx={{ transform: open ? 'initial' : 'rotate(-90deg)' }}
                            />
                        </IconButton>
                    }
                >
                    <ListItem>
                        <Typography
                            level="inherit"
                            sx={{
                                fontWeight: open ? 'bold' : undefined,
                                color: open ? 'text.primary' : 'inherit',
                            }}
                        >
                            Game Configs
                        </Typography>
                        <Typography component="span" level="body-xs">
                            {gameConfigs.length}
                        </Typography>
                    </ListItem>
                    {open && (
                        <List sx={{ '--ListItem-paddingY': '8px' }}>
                            {gameConfigs.map((gameConfig, index) => (
                                <ListItem key={index}>
                                    <ListItemButton selected={checkGCSelected("game-configs", gameConfig)}
                                                    component="a"
                                                    href={"/help/game-configs/" + gameConfig.name}>{gameConfig.name}</ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </ListItem>
            </List>
        </Box>
    );
}
