import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../quill.dark.css';
import {useEffect, useRef, useState} from "react";
import TurndownService from "turndown";
import {marked} from "marked";
import Sheet from "@mui/joy/Sheet";

export default function RichTextEditor(props) {
    const [value, setValue] = useState(props.value);
    const quillRef = useRef();
    const toolbarOptions = [
        [{ 'header': [2,3,4]}],
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block', 'code'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }]
    ];

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const update = function(html) {
        setValue(html);
        props.onChange(html);
    };

    return (
        <Sheet sx={{ background: 'white' }}>
            <ReactQuill
                theme="snow"
                ref={quillRef}
                value={value}
                onChange={update}
                modules={{
                    toolbar: toolbarOptions,
                    clipboard: {
                        matchVisual: false,
                    }
                }}
                style={{ height: 'calc(100vh - 300px)', marginBottom: "40px" }}
            />
        </Sheet>
    );
}
