import Button from "@mui/joy/Button";
import {Grid, Link, Skeleton, Stack, Table, Typography} from "@mui/joy";
import RichTextEditor from "../../components/rich-text-editor";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import ApiClient from "../../utils/api-client";
import NotificationContext from "../../contexts/notification-context";
import WikiSummaryFrame from "../../components/wiki-summary-frame";
import DOMPurify from 'dompurify';
import BreadcrumbNavigation from "../../components/breadcrumb-navigation";
import Chip from "@mui/joy/Chip";
import Sheet from "@mui/joy/Sheet";
import apiClient from "../../utils/api-client";

export default function AdminHelpListFragment() {
    const navigate = useNavigate();
    const [helpPages, setHelpPages] = useState([]);
    const { sendError } = useContext(NotificationContext);

    const fetchHelpPages = () => {
        const result = [
            {
                url: "overview",
                title: "Overview"
            },
            {
                url: "templates",
                title: "Templates"
            },
            {
                url: "selectabletags",
                title: "Selectable tags"
            }
        ];
        setHelpPages(result);
    }

    useEffect(() => {
        fetchHelpPages();
    }, []);

    return (
        <>
            <Sheet variant="outlined" sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm' }} >
                <Table
                    aria-labelledby="tableTitle"
                    hoverRow
                    sx={{
                        '--TableCell-headBackground': 'transparent',
                        '--TableCell-selectedBackground': (theme) =>
                            theme.vars.palette.success.softBg,
                        '& thead th:nth-child(1)': {
                            width: '40px',
                        },
                        '& thead th:nth-child(2)': {
                            width: '30%',
                        },
                        '& tr > *:nth-child(n+3)': { textAlign: 'right' },
                    }}>
                    <thead>
                    <tr>
                        <th style={{ width: "200px" }}>Identifier</th>
                        <th style={{ width: '75%' }}>Title</th>
                    </tr>
                    </thead>
                    <tbody>
                        {helpPages.map((item) => (
                            <React.Fragment key={item.id}>
                                <tr style={{ cursor: "pointer" }} onClick={() => navigate("/admin/documentation/" + item.url)}>
                                    <td>
                                        <code>{item.url}</code>
                                    </td>
                                    <td>{item.title}</td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            </Sheet>
        </>
    );
}
