import {useEffect, useState} from "react";
import ApiClient from "../utils/api-client";

let CACHE_WOW_ICONS = {};

const WoWIcon = function(props) {
    const [name, setName] = useState(props.icon ?? "inv_misc_questionmark.jpg");
    const getSize = function() {
        switch (props.size ?? "md") {
            case "sm":
                return "18px";
            case "lg":
                return "48px";
            default:
                return "24px";
        }
    }

    const fetchIconId = async function(value) {
        if (CACHE_WOW_ICONS[value] != null) {
            setName(CACHE_WOW_ICONS[value]);
            return;
        }

        const client = new ApiClient();
        try {
            const iconInfo = await client.getIconInfo(value);
            setName(iconInfo.fileName);
            CACHE_WOW_ICONS[value] = iconInfo.fileName;
        }
        catch (e) {
            setName("inv_misc_questionmark.jpg");
        }
    }
    const getUrl = function() {
        switch(props.size ?? "md") {
            case "lg":
                return "https://wow.zamimg.com/images/wow/icons/large/" + name;
            default:
                return "https://wow.zamimg.com/images/wow/icons/small/" + name;
        }
    }

    useEffect(() => {
        if (name === "inv_misc_questionmark.jpg" && props.iconId !== 0) {
            fetchIconId(props.iconId);
        }
    }, [props.iconId, name]);

    /*useEffect(() => {
        setName("inv_misc_questionmark.jpg");
    }, [props.iconId])*/

    return (
        <img src={getUrl()} style={{ width: getSize(), height: getSize() }} alt="Wow Icon" />
    );
};

export default WoWIcon;
