import Sheet from "@mui/joy/Sheet";
import {
    Box,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, Input, ListItemButton,
    Modal,
    ModalClose,
    ModalDialog,
    Stack,
    Textarea,
    Typography
} from "@mui/joy";
import Button from "@mui/joy/Button";
import React, {useEffect, useState} from "react";
import LogicTags from "./logic-tags";
import SelectTarget from "./select-target";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import WoWIcon from "./wow-icon";
import {getColorForClassSpec} from "../utils/util";
import NotificationContext from "../contexts/notification-context";
import ApiClient from "../utils/api-client";

export default function ActionTargetDialog({ open, onClose, valueTargets, valueTags, valueMaxCount, onChange }) {
    const [selectableTags, setSelectableTags] = useState(valueTags);
    const [targets, setTargets] = useState(valueTargets ?? "");
    const [maxCount, setMaxCount] = useState(valueMaxCount ?? -1);
    const [whitelist, setWhitelist] = useState([]);
    const [tagifyProps, setTagifyProps] = useState({});
    const [foundAbilities, setFoundAbilities] = useState([]);

    const fetchAvailableTags = async () => {
        const client = new ApiClient();
        try {
            const currentGameConfig = await client.getCurrentGameConfig();
            const foundTags = await client.findTags(currentGameConfig.name, "ability");
            let whitelistedTags = [];
            for (const foundTag of foundTags) {
                whitelistedTags.push("prefer:" + foundTag);
                whitelistedTags.push("avoid:" + foundTag);
                whitelistedTags.push("never:" + foundTag);
                whitelistedTags.push(foundTag);
            }
            setTagifyProps({ whitelist: whitelistedTags, loading: false });
            setWhitelist(whitelistedTags);
        }
        catch (e) {
            return;
        }
    }

    const fetchMatchingAbilities = async() => {
        const client = new ApiClient();
        try {
            const currentGameConfig = await client.getCurrentGameConfig();
            const result = await client.findAbilities(currentGameConfig.name, targets, selectableTags);
            setFoundAbilities(result);
        }
        catch (e) {
            setFoundAbilities([]);
        }

    }

    const handleTargetChange = function(e, newValue) {
        setTargets(newValue);
    }

    const handleMaxCountChange = function(e) {
        if (e.target.checked) {
            setMaxCount(0);
        } else {
            setMaxCount(-1);
        }
    }

    const confirmChanges = function() {
        onChange(targets, selectableTags, maxCount);
    }

    useEffect(() => {
        if (open) {
            setTagifyProps({loading: true});
            fetchAvailableTags();
            fetchMatchingAbilities();
        }
    }, [open]);

    return (
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={open}
            onClose={onClose}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <ModalDialog size="md" color="neutral" variant="outlined" style={{ width: '800px'}}>
                <DialogTitle>
                    Change Selectors for abilities
                </DialogTitle>
                <DialogContent>
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                    </Typography>
                    <Stack direction="row" alignItems="center">
                        <Checkbox label="Limit number of spells to"
                                  size="md"
                                  checked={maxCount !== -1}
                                  variant="solid"
                                  value="on"
                                  onChange={handleMaxCountChange}
                        />
                        <Input type="number"
                               label="Max Count"
                               variant="outlined"
                               disabled={maxCount === -1}
                               slotProps={{ input: { step: "1", min: "0", max: "99" } }}
                               style={{ marginLeft: "10px", opacity: (maxCount === -1) ? 0 : 1 }}
                               value={maxCount}
                               onChange={ (e) => setMaxCount(e.target.value) }/>
                    </Stack>

                    <SelectTarget value={targets}
                                  onChange={handleTargetChange} />
                    <Box style={{ height: "300px" }}>
                        <LogicTags
                            whitelist={whitelist}
                            value={selectableTags}
                            placeholder="Selectable tags..."
                            {...tagifyProps}
                            onChange={ (e) => setSelectableTags(e.target.value) }
                        />
                    </Box>

                    <Button size="sm" style={{ marginTop: '10px' }} variant="outlined" onClick={fetchMatchingAbilities}>Check</Button>
                    <Divider style={{ marginTop: '5px', marginBottom: '5px' }}/>

                    <Typography level="body-md">We found {foundAbilities.length} abilities that could be used with these settings :</Typography>
                    <Sheet
                        variant="outlined"
                        sx={{
                            maxHeight: 140,
                            overflow: 'auto',
                            borderRadius: 'sm',
                        }}>
                        <List
                            size="sm"
                            sx={{
                                borderRadius: 'sm',
                                height: '200px'
                            }}>
                            {foundAbilities.map((ability, index) => (
                                <ListItem key={index}>
                                    <WoWIcon iconId={ability.iconId} />{ability.name}&nbsp;{ability.variant}
                                    <Typography level="body-xs" style={{ color: getColorForClassSpec(ability.from) }}>({ability.from})</Typography>
                                    <Typography level="body-xs" >[{ability.relevance}]</Typography>
                                </ListItem>
                            ))}

                        </List>
                    </Sheet>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={confirmChanges}>OK</Button>
                    <Button variant="outlined" onClick={onClose}>Cancel</Button>
                    <Button variant="plain" type="a" href="/help">See Help</Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
}
