import {extendTheme} from "@mui/joy";

/*const chakraTheme = extendTheme({
    colorSchemes: {
        dark: {
            palette: {
                primary: {
                    solidBg: '#319795',
                    solidHoverBg: '#2C7A7B',
                    solidActiveBg: '#285E61',
                    outlinedColor: '#2C7A7B',
                    outlinedBorder: '#2C7A7B',
                    outlinedHoverBorder: undefined,
                    outlinedHoverBg: '#082e30',
                    outlinedActiveBg: '#B2F5EA',
                    plainColor: '#319795',
                    plainHoverBg: '#082e30',
                    softBg: '#082e30',
                    softHoverBg: '#2C7A7B',
                    softActiveBg: '#285E61'
                },
                focusVisible: 'rgba(66, 153, 225, 0.6)',
            },
        },
    },
    focus: {
        default: {
            outlineWidth: '3px',
        },
    },
    fontFamily: {
        body: 'Inter, var(--chakra-fontFamily-fallback)',
    },
    components: {
        JoyButton: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    '&:focus': theme.focus.default,
                    fontWeight: 600,
                    ...(ownerState.size === 'md' && {
                        borderRadius: '0.375rem',
                        paddingInline: '1rem',
                    }),
                }),
            },
        },
    },
});*/

const chakraTheme = extendTheme({
    colorSchemes: {
        dark: {
            palette: {
                primary: {
                    solidBg: '#008080', // Teinte entre vert et bleu
                    solidHoverBg: '#006666', // Un peu plus sombre pour le hover
                    solidActiveBg: '#004d4d', // Encore plus sombre pour l'active
                    softActiveBg: '#004d4d',         // Fond plus doux pour la variante soft
                    softHoverBg: '#003333',   // Fond au survol pour soft
                    softBg: '#002222',  // Fond actif pour soft
                    outlinedBorder: '#008080',
                    outlinedHoverBg: '#004d4d11',
                    outlinedColor: '#008080',
                    plainHoverBg: '#004d4d11',
                    plainColor: '#008080',
                },
                background: {
                    body: '#121212',
                    level1: '#333',
                    level2: '#444'
                },
                text: {
                    primary: '#fff',
                    secondary: '#aaa'
                }
            }
        }
    },
    components: {
        JoyButton: {
            styleOverrides: {
                root: {
                    '&.JoyButton-plain': {   // Styles spécifiques pour plain
                        backgroundColor: 'transparent',
                        color: '#fff'
                    },
                    '&.JoyButton-outlined': {  // Styles spécifiques pour outlined
                        border: '1px solid #fff',
                        backgroundColor: 'transparent',
                        color: '#fff'
                    }
                }
            }
        }
    },
    mode: 'dark', // Définir le mode sombre comme défaut
});
export default chakraTheme;
