import Button from "@mui/joy/Button";
import {Grid, Link, Stack, Typography} from "@mui/joy";
import RichTextEditor from "../../components/rich-text-editor";
import {useParams, useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import ApiClient from "../../utils/api-client";
import NotificationContext from "../../contexts/notification-context";
import WikiSummaryFrame from "../../components/wiki-summary-frame";
import DOMPurify from 'dompurify';
import BreadcrumbNavigation from "../../components/breadcrumb-navigation";
import {HelpPageForm} from "../../forms/help-page-form";

export default function AdminEditHelpFragment() {
    let { url } = useParams();
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState({});
    const { sendError, sendSuccess } = useContext(NotificationContext);

    const fetchHelpPage = async () => {
        const client = new ApiClient();
        try {
            const page = await client.getHelpPage(url);
            setPage(page);
        }
        catch (e) {
            sendError(e);
        }
    }

    const saveHelpPage = async (savedPage) => {
        try {
            const client = new ApiClient();
            await client.setHelpPage(url, savedPage);
            sendSuccess("Page saved!");
        }
        catch (e) {
            sendError(e);
        }
    }

    useEffect(() => {
        fetchHelpPage();
    }, []);

    return (
        <>
            <HelpPageForm value={page} onSave={(e) => saveHelpPage(e)}/>
        </>
    );
}
