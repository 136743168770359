import Button from "@mui/joy/Button";
import Sheet from "@mui/joy/Sheet";
import {
    Box, Card, CircularProgress, FormHelperText,
    FormLabel,
    Grid, LinearProgress, ListItemDecorator, Option, Select,
    Stack, Tab, Table, TabList, TabPanel, Tabs, Tooltip,
    Typography
} from "@mui/joy";
import RaidFrame from "../components/raid-frame";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faArrowRight,
    faArrowRightLong, faChartGantt,
    faDragon, faHeadset,
    faNoteSticky,
    faPhone
} from "@fortawesome/free-solid-svg-icons";
import React, {useContext, useEffect, useState} from "react";
import WoWIcon from "../components/wow-icon";
import CodeWithLineBreaks from "../components/code-with-line-breaks";
import NotificationContext from "../contexts/notification-context";
import {formatTime} from "../utils/util";
import { v4 as uuidv4 } from 'uuid';
import AssignationTable from "../components/assignation-table";
import RosterAbilitiesTable from "../components/roster-abilities-table";
import ApiClient from "../utils/api-client";
import AuthContext from "../contexts/auth-context";
import AssignationFrame from "../components/assignation-frame";

export default function MRTNotePage() {
    const [templateSelectedId, setTemplateSelectedId] = useState();
    const [raid, setRaid] = useState(null);
    const [showcasedTemplates, setShowcasedTemplates] = useState([]);
    const [myTemplates, setMyTemplates] = useState([]);
    const [displayedTemplates, setDisplayedTemplates] = useState([]);
    const [result, setResult] = useState({ assignations: [], note: ""});
    const [computing, setComputing] = useState(false);
    const [error, setError] = useState(null);
    const [tab, setTab] = useState(0);
    const { sendError, sendSuccess } = useContext(NotificationContext);
    const { isAuthenticated } = useContext(AuthContext);

    const fetchShowcasedTemplates = async () => {
        try {
            const client = new ApiClient();
            const result = await client.getTemplates();
            setShowcasedTemplates(result);
            if (displayedTemplates.length === 0) {
                setDisplayedTemplates(result);
            }
        }
        catch (e) {
            sendError(e);
        }
    }

    const fetchMyTemplates = async () => {
        try {
            const client = new ApiClient();
            const result = await client.getMyTemplates();
            setMyTemplates(result);
        }
        catch (e) {
            sendError(e);
        }
    }

    const buildMRTNote = async () => {
        setComputing(true);
        try {
            const client = new ApiClient();
            const resultOfMRTNote = await client.buildMRTNote({
                roster: raid,
                template: templateSelectedId
            });
            setComputing(false);
            setResult(resultOfMRTNote);
            sendSuccess("MRT note built!");
        }
        catch (e) {
            setComputing(false);
            sendError(e);
        }
    }

    const filterDisplayedTemplates = (value) => {
        if (value === "predefined") {
            setDisplayedTemplates(showcasedTemplates);
        }
        else {
            setDisplayedTemplates(myTemplates);
        }
    }

    useEffect(() => {
        async function fetch() {
            await fetchShowcasedTemplates();
            if (isAuthenticated) {
                await fetchMyTemplates();
            }
        }
        fetch();

    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid lg={6}>
                    <Typography level="h1">Build MRT Note</Typography>
                    <Typography level="body-lg">Build a MRT Note based on one of our numerous ready-to-use templates, or one of your specific templates.</Typography>
                    <Sheet variant="soft" elevation={2} sx={{ padding: "10px", marginTop: "20px" }}>
                            <FormLabel>Choose a template:</FormLabel>
                            <Grid container spacing={2} style={{ marginTop: "2px", marginBottom: "2px" }}>
                                <Grid xs={6}>
                                    <Select defaultValue="predefined" onChange={(e, newValue) => { filterDisplayedTemplates(newValue)}}>
                                        <Option value="predefined">Predefined templates</Option>
                                        {isAuthenticated &&
                                            <Option value="mine">My templates</Option>
                                        }
                                    </Select>
                                </Grid>
                                <Grid xs={6}>
                                    <Select value={templateSelectedId} onChange={(e,newValue) => { setTemplateSelectedId(newValue)}}>
                                        {displayedTemplates.map((template, index) => (
                                            <Option key={template.id} value={template.id}>{template.title}</Option>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                            <FormHelperText>
                                <Typography level="body-sm" color="warning">This template is better suited for raids of 20 members.</Typography>
                            </FormHelperText>

                            <Stack direction="row" gap={2} style={{ marginTop: "10px" }}>
                                <Button size="lg" color="primary" onClick={buildMRTNote}>Build MRT Note</Button>
                                <Button size="lg" color="primary" variant="outlined">Copy to My templates</Button>
                            </Stack>
                    </Sheet>
                </Grid>
                <Grid lg={6}>
                    <Sheet sx={{ m: "20px" }}>
                        <RaidFrame onChange={(e) => setRaid(e.value)} />
                    </Sheet>
                </Grid>
            </Grid>
            <Typography level="h2">Result</Typography>
            <AssignationFrame computing={computing} result={result} />
        </>
    );
}
