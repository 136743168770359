import {Box, Skeleton, Stack, Table, Typography} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import React, {useContext, useEffect, useState} from "react";
import Button from "@mui/joy/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from "react-router-dom";
import Chip from "@mui/joy/Chip";
import ApiClient from "../utils/api-client";
import NotificationContext from "../contexts/notification-context";
import AuthContext from "../contexts/auth-context";

export default function MyStrategiesListPage() {
    const [templates, setTemplates] = useState([]);
    const navigate = useNavigate()
    const { sendError } = useContext(NotificationContext);
    const { isAuthenticated } = useContext(AuthContext);
    const fetchMyTemplates = async () => {
        try {
            let client = new ApiClient();
            const result = await client.getMyTemplates();
            setTemplates(result);
        }
        catch (e) {
            sendError(e);
        }
    }

    useEffect(() => {
        fetchMyTemplates();

    }, []);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Stack>
                    <Typography level="h1">My Strategies</Typography>
                    <Typography level="title-lg">List of your personal strategies</Typography>
                </Stack>
                {isAuthenticated &&
                    <Button
                        color="primary"
                        startDecorator={<FontAwesomeIcon icon={faAdd} />}
                        onClick={() => navigate("/my-strategies/new")}
                        size="sm"
                    >
                        Create strategy
                    </Button>
                }
            </Box>

            <Sheet variant="outlined" sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm' }} >
                <Table
                    aria-labelledby="tableTitle"
                    hoverRow
                    sx={{
                        '--TableCell-headBackground': 'transparent',
                        '--TableCell-selectedBackground': (theme) =>
                            theme.vars.palette.success.softBg,
                        '& thead th:nth-child(1)': {
                            width: '40px',
                        },
                        '& thead th:nth-child(2)': {
                            width: '30%',
                        },
                        '& tr > *:nth-child(n+3)': { textAlign: 'right' },
                    }}>
                    <thead>
                    <tr>
                        <th style={{ width: "200px" }}>Identifier</th>
                        <th style={{ width: '75%' }}>Title</th>
                    </tr>
                    </thead>
                    <tbody>
                    {templates.length === 0 ? (
                        <tr><td colSpan={3}>
                            <Typography>
                                <Skeleton>
                                    Lorem ipsum is placeholder text commonly used in the graphic, print, and
                                    publishing industries.
                                </Skeleton>
                            </Typography>
                        </td></tr>
                    ) : (
                        // Display items.
                        <>
                            {templates.map((item) => (
                                <React.Fragment key={item.id}>
                                    {isAuthenticated ? (
                                        <tr style={{ cursor: "pointer" }} onClick={() => navigate("/my-strategies/" + item.id)}>
                                            <td>
                                                <code>{item.id}</code>
                                            </td>
                                            <td>{item.title} <Chip color="primary">{item.itemsCount}</Chip></td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td>
                                                <code>{item.id}</code>
                                            </td>
                                            <td>{item.title} <Chip color="primary">{item.itemsCount}</Chip></td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </>
                    )}
                    </tbody>
                </Table>
            </Sheet>
        </>
    );
}
