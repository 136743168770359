import Button from "@mui/joy/Button";
import {Grid, Link, Stack, Typography} from "@mui/joy";
import RichTextEditor from "../components/rich-text-editor";
import {useParams, useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import ApiClient from "../utils/api-client";
import NotificationContext from "../contexts/notification-context";
import WikiSummaryFrame from "../components/wiki-summary-frame";
import DOMPurify from 'dompurify';
import BreadcrumbNavigation from "../components/breadcrumb-navigation";

export default function HelpPage() {
    let { url } = useParams();
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState({});
    const { sendError } = useContext(NotificationContext);

    const fetchData = async () => {
        const client = new ApiClient();
        try {
            const page = await client.getHelpPage(url);
            setPage(page);
        }
        catch (e) {
            sendError(e);
        }
    }

    const saveHelp = async function() {
        /*const data = {
            title: "Hello World",
            contents
        };
        const dataJSON = JSON.stringify(data);
        const response = await fetch("http://localhost:3001/help", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            mode: "cors",
            body: dataJSON
        });
        await response.json();*/
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <BreadcrumbNavigation>
                <Link color="neutral">
                    {page.title}
                </Link>
            </BreadcrumbNavigation>

            <Grid container spacing={1}>
                <Grid xs={12} sm={12} md={9}>
                    <Typography level="h1">{page.title}</Typography>
                    <div style={{ color: "lightgray"}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(page.contents) }} />
                </Grid>
                <Grid xs={12} sm={12} md={3}>
                    <WikiSummaryFrame selected={url} />
                </Grid>
            </Grid>
        </>
    );
}
