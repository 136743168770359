import {Alert, FormControl, FormLabel, Input, Link, Typography} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import Button from "@mui/joy/Button";
import LoginForm from "../forms/login-form";
import * as React from "react";
import ApiClient from "../utils/api-client";
import RegisterForm from "../forms/register-form";
import {useContext, useState} from "react";
import AuthContext from "../contexts/auth-context";
import {useNavigate} from "react-router-dom";
import NotificationContext from "../contexts/notification-context";
import WaitListForm from "../forms/wait-list-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDragon} from "@fortawesome/free-solid-svg-icons";

export default function WaitListPage() {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const {sendError, sendSuccess, clear} = useContext(NotificationContext);
    const error = useState(null);
    const [querySent, setQuerySent] = useState(0);

    const waitListSignUp = async function (newUser) {
        try {
            const client = new ApiClient();
            const token = await client.register(newUser, true);
            sendSuccess("Added to the Wait list");
            setQuerySent(1);
        } catch (e) {
            sendError(e);
            setQuerySent(-1);
        }
    }

    return (
        <>
            <Sheet
                sx={{
                    width: 300,
                    mx: 'auto', // margin left & right
                    my: 4, // margin top & bottom
                    py: 3, // padding top & bottom
                    px: 2, // padding left & right
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: 'sm',
                    boxShadow: 'md',
                }}
                style={{ marginTop: "150px" }}
                variant="outlined"
            >
                <div>
                    <Typography level="h3" style={{ textAlign: "center" }}><FontAwesomeIcon icon={faDragon} /> RaidLead</Typography>
                    <Typography level="body-md" style={{ textAlign: "center" }}>Reserve Your Spot</Typography>
                </div>
                <Typography level="body-sm">
                    Become part of our community early by joining the waitlist.
                    You’ll receive firsthand updates and the opportunity to influence future developments.
                </Typography>
                {querySent !== 0 ? (
                    <>
                        {querySent === 1 &&
                            <Alert color="success">You are now in the wait list!</Alert>
                        }
                        {querySent === -1 &&
                            <Alert color="danger">Something goes wrong!</Alert>
                        }
                    </>
                ) : (
                    <WaitListForm onSubmit={(newUser) => waitListSignUp(newUser)}
                                  error={error}/>
                )}

            </Sheet>
        </>
    );
}
