import {Alert, Checkbox, Divider, FormControl, FormLabel, Input, Stack, Typography} from "@mui/joy";
import WoWIcon from "../components/wow-icon";
import Button from "@mui/joy/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDisplay, faInfo, faSave} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import ApiClient from "../utils/api-client";

export default function SettingsDisplayForm(props) {
    const [settings, setSettings] = useState(props.settings ?? {});

    const handleSaveClicked = function(e) {
        props.onSave(settings);
    }

    const updateSetting = (key, value) => {
        const newSettings = {...settings};
        newSettings[key] = value;
        setSettings(newSettings);
    }

    useEffect(() => {
        setSettings(props.settings ?? {});
    }, [props.settings]);

    return (
        <>
            <Stack direction="column" gap={2}>
                <Checkbox label="Display all alternatives of a spell"
                          checked={settings["alternativesOfSpell"] ?? false}
                          value="1"
                          onChange={(e) => updateSetting("alternativesOfSpell", e.target.checked)} />
                <Typography level="body-sm" style={{ marginLeft: "30px" }}>
                    Allows to display all alternatives of a spell while previewing. That means you can see in
                    suitable spells for a collection of tags multiple times the same spell,
                    with an applied variant because of a specific selection of talents.
                </Typography>
                <Checkbox label="Display variant information of a spell"
                          checked={settings["displayVariantInfo"] ?? false}
                          value="1"
                          onChange={(e) => updateSetting("displayVariantInfo", e.target.checked)} />
                <Typography level="body-sm" style={{ marginLeft: "30px" }}>
                    Display complete name with variant for spells in the assignations and note.
                </Typography>
            </Stack>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }}/>
            <Stack direction="row" style={{ marginTop: "20px" }} gap={2} justifyContent="flex-end">
                <Button variant="soft" color="neutral">Cancel</Button>
                <Button variant="solid"
                        color="primary"
                        startDecorator={<FontAwesomeIcon icon={faSave} />}
                        onClick={handleSaveClicked}>Save</Button>
            </Stack>
        </>
    );
}
