import {
    FormControl,
    FormLabel,
    Grid,
    Input,
    Select,
    Option,
    Table,
    Typography,
    Stack,
    Checkbox,
    ButtonGroup
} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import React, {useCallback, useEffect, useState} from "react";
import ChooseIconButton from "../components/choose-icon-button";
import Button from "@mui/joy/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faGear, faPlus, faRemove, faSave, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import LogicTags from "../components/logic-tags";
import WoWIcon from "../components/wow-icon";
import IconInput from "../components/icon-input";
import SelectTarget from "../components/select-target";
import ImportDialog from "../components/import-dialog";
import ActionTargetDialog from "../components/action-target-dialog";
import DurationInput from "../components/duration-input";
import IconButton from "@mui/joy/IconButton";
import { v4 as uuidv4 } from 'uuid';
import {useNavigate} from "react-router-dom";

function StrategyForm(props) {
    const [id, setId] = useState("");
    const [title, setTitle] = useState("");
    const [maxDuration, setMaxDuration] = useState(0);
    const [imageUrl, setImageUrl] = useState("");
    const [category, setCategory] = useState("");

    const [actions, setActions] = useState([]);
    //const [formData, setFormData] = useState(props.value ?? {id: '', title: '', maxDuration: 0, actions: []});

    const addNewAction = function() {
        let modifiedActions = [...actions];
        modifiedActions.push({
            uuid: uuidv4(),
            timestamp: 0,
            enabled: true,
            iconId: 0,
            priority: 1,
            description: "",
            maxCount: -1,
            targets: "",
            selectableTags: "",
        });
        setActions(modifiedActions);
    }

    const updateAction = function(index, value) {
        let modifiedActions = [...actions];        modifiedActions[index] = value;
        setActions(modifiedActions);
    }

    const copyAction = function(base) {
        let modifiedActions = [...actions];
        let newBase = {...base};
        newBase.uuid = uuidv4();
        modifiedActions.push(newBase);
        setActions(modifiedActions);
    }

    const removeAction = function(i) {
        let modifiedActions = [...actions];
        modifiedActions.splice(i, 1);
        setActions(modifiedActions);
    }

    const handleChangeTitle = function(e) {
        setTitle(e.target.value);
    }

    const handleChangeMaxDuration = function(e) {
        setMaxDuration(e.target.value);
    }

    const handleChangeImageUrl = function(e) {
        setImageUrl(e.target.value);
    }

    const handleChangeCategory = function(e, newValue) {
        setCategory(newValue);
    }

    const handleSaveClicked = function(e) {
        const template = {
            id,
            title,
            maxDuration,
            category,
            imageUrl,
            actions,
        };
        props.onSave(template);
    }

    const handleCancelClicked = function(e) {
        props.onCancel();
    }

    useEffect(() => {
        setId(props.value.id ?? "");
    }, [props.value.id]);

    useEffect(() => {
        setTitle(props.value.title ?? "");
    }, [props.value.title]);

    useEffect(() => {
        setCategory(props.value.category ?? "");
    }, [props.value.category]);

    useEffect(() => {
        setImageUrl(props.value.imageUrl ?? "");
    }, [props.value.imageUrl]);

    useEffect(() => {
        let values = props.value.actions ?? [];
        for (let action of values) {
            action.uuid = uuidv4();
        }
        setActions(values);
    }, [props.value.actions]);

    useEffect(() => {
        setMaxDuration(props.value.maxDuration);
    }, [props.value.maxDuration]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid xs={4}>
                    <FormControl>
                        <FormLabel>Identifier</FormLabel>
                        <Input readOnly
                               value={id} />
                    </FormControl>
                </Grid>
                <Grid xs={8}>
                    <FormControl>
                        <FormLabel>Title</FormLabel>
                        <Input required
                               value={title}
                               onChange={handleChangeTitle} />
                    </FormControl>
                </Grid>
                <Grid xs={3}>
                    <FormControl style={{ width: "200px" }}>
                        <FormLabel>Total Fight duration</FormLabel>
                        <DurationInput required
                                       value={maxDuration ?? 0}
                                       onChange={handleChangeMaxDuration} />
                    </FormControl>
                </Grid>
                <Grid xs={6}>
                    <FormControl>
                        <FormLabel>Category</FormLabel>
                        <Select required value={category} onChange={handleChangeCategory}>
                            <Option value="voti">Vault of the Incarnates (VOTI)</Option>
                            <Option value="asc">Aberrus, the Shadowed Crucible (ASC)</Option>
                            <Option value="adh">Amirdrassil, the Dream's Hope (ADH)</Option>
                            <Option value="ext">Exterior</Option>
                            <Option value="misc">Miscellaneous</Option>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={8}>
                    <FormControl>
                        <FormLabel>Image url</FormLabel>
                        <Input required
                               value={imageUrl}
                               onChange={handleChangeImageUrl} />
                    </FormControl>
                </Grid>
            </Grid>

            <br/>
            <Sheet variant="outlined" sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm' }} >
                <Table borderAxis="none"
                       aria-labelledby="tableTitle">
                    <thead>
                    <tr>
                        <th style={{ width: '85px' }}>Timestamp</th>
                        <th style={{ width: '25px' }}></th>
                        <th style={{ width: '120px' }}>Icon</th>
                        <th>Description</th>
                        <th style={{ width: '70px' }}>Priority</th>
                        <th style={{ width: '160px' }}>Selectors</th>
                        <th style={{ width: '70px' }}></th>
                    </tr>
                    </thead>
                    <tbody>
                        {actions.map((item, index) => (
                            <StrategyFormActionItem key={item.uuid}
                                                    element={index}
                                                    value={item}
                                                    onChange={(value) => updateAction(index, value)}
                                                    onCopy={(base) => copyAction(base)}
                                                    onRemove={(i) => removeAction(i)}
                            />
                        ))}
                        <tr>
                            <td colSpan="7">
                                <ButtonGroup color="primary" variant="outlined" sx={{ width: '100%', justifyContent: 'center' }}>
                                    <Button onClick={() => addNewAction()}
                                            startDecorator={<FontAwesomeIcon icon={faPlus}/>}>
                                        Add new action
                                    </Button>
                                    <Button onClick={() => addNewAction()}
                                            startDecorator={<FontAwesomeIcon icon={faPlus}/>}>
                                        Add new phase change
                                    </Button>
                                </ButtonGroup>

                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Sheet>

            <Stack direction="row"
                   spacing={2}
                   style={{ marginTop: "10px" }}
                   justifyContent="flex-end">

                <Button size="lg"
                        variant="soft"
                        color="neutral"
                        onClick={handleCancelClicked}>
                    Cancel
                </Button>
                <Button size="lg"
                        variant="solid"
                        startDecorator={<FontAwesomeIcon icon={faSave} />}
                        onClick={handleSaveClicked}>
                    Save
                </Button>
            </Stack>
        </>
    )
}

function StrategyFormActionItem(props) {
    const [item, setItem] = useState(props.value);
    const [dialogOpened, setDialogOpened] = useState(false);
    const i = props.element;

    const update = function(updatedValue) {
        let newItem = {
            ...item
        };
        for (const key in updatedValue) {
            newItem[key] = updatedValue[key];
        }
        setItem(newItem);
        props.onChange(newItem);
    }

    const copyItem = function(base) {
        if (props.onCopy) {
            props.onCopy(base);
        }
    }

    const removeItem = function(index) {
        if (props.onRemove) {
            props.onRemove(index);
        }
    }

    return (
        <>
            <tr>
                <td>
                    <DurationInput
                        variant={item.enabled ? "outlined" : "soft"}
                        endDecorator="s"
                        value={item.timestamp}
                        onChange={ (e) => update({timestamp: e.target.value}) }
                    />
                </td>
                <td>
                    <Checkbox label="" size="lg"
                              checked={item.enabled}
                              variant="plain"
                              onChange={(e) => update({enabled: e.target.checked})} />
                </td>
                <td>
                    <IconInput value={item.iconId}
                               variant={item.enabled ? "outlined" : "soft"}
                               onChange={ (e) => update({iconId: e.target.value}) } />
                </td>
                <td>
                    <Input type="text"
                           label="Descriptionn"
                           variant="outlined"
                           variant={item.enabled ? "outlined" : "soft"}
                           value={item.description}
                           onChange={ (e) => update({description: e.target.value}) }/>
                </td>
                <td>
                    <Input
                        type="number"
                        label="Priority"
                        variant="outlined"
                        variant={item.enabled ? "outlined" : "soft"}
                        slotProps={{ input: { step: "0.1", min: "0", max: "1" } }}
                        value={item.priority}
                        onChange={ (e) => update({priority: e.target.value}) }
                    />
                </td>
                <td>
                    <Button variant="soft"
                            style={{ width: "100%" }}
                            onClick={(e) => setDialogOpened(true)}>
                        Select Abilities...
                    </Button>
                </td>
                <td>
                    <ButtonGroup spacing="2px" variant="plain">
                        <IconButton color="neutral"
                                    onClick={(e) => copyItem(item)}>
                            <FontAwesomeIcon icon={faCopy} />
                        </IconButton>
                        <IconButton color="danger"
                                    onClick={(e) => removeItem(i)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </IconButton>
                    </ButtonGroup>
                </td>
            </tr>
            <ActionTargetDialog open={dialogOpened}
                                valueTargets={item.targets}
                                valueTags={item.selectableTags}
                                valueMaxCount={item.maxCount}
                                onClose={() => setDialogOpened(false)}
                                onChange={(a0, a1, a2) => {
                                    setDialogOpened(false);
                                    update({targets: a0, selectableTags: a1, maxCount: a2});
                                }} />
        </>
    );
}

function StrategyFormPhaseItem(props) {
    const [item, setItem] = useState(props.value);
    const [dialogOpened, setDialogOpened] = useState(false);
    const i = props.element;

    return (
        <>
            <tr>
                <td colSpan={6}>
                    Ceci est un changement de phase
                </td>
            </tr>
        </>
    );
}


export {
    StrategyForm,
    StrategyFormActionItem,
    StrategyFormPhaseItem
};
