import logo from "../../logo.svg";
import Button from "@mui/joy/Button";
import Sheet from "@mui/joy/Sheet";
import {
    Alert, Checkbox,
    Divider,
    FormControl, FormHelperText,
    FormLabel,
    Grid, Input,
    ListItemButton,
    ListItemContent,
    ListItemDecorator, Stack,
    Typography
} from "@mui/joy";
import RaidFrame from "../components/raid-frame";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleRight,
    faBank,
    faCreditCard,
    faDisplay,
    faInfo,
    faKey,
    faSave, faTree,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import React, {useContext, useEffect, useState} from "react";
import ApiClient from "../utils/api-client";
import NotificationContext from "../contexts/notification-context";
import ListDivider from "@mui/joy/ListDivider";
import WoWIcon from "../components/wow-icon";
import SettingsDefaultSpecForm from "../forms/settings-default-spec-form";
import SettingsDisplayForm from "../forms/settings-display-form";
import SettingsAuthenticationForm from "../forms/settings-authentication-form";
import BreadcrumbNavigation from "../components/breadcrumb-navigation";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import AdminHelpListFragment from "./fragments/admin-help-list-fragment";
import AdminEditHelpFragment from "./fragments/admin-edit-help-fragment";
import SettingsPatreonFragment from "./fragments/settings-patreon-fragment";
import SettingsAccountFragment from "./fragments/settings-account-fragment";
import {faPatreon} from "@fortawesome/free-brands-svg-icons";
import SettingsDisplayFragment from "./fragments/settings-display-fragment";
import SettingsAuthenticationFragment from "./fragments/settings-authentication-fragment";
import SettingsDefaultsFragment from "./fragments/settings-defaults-fragment";

export default function SettingsPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState({ pseudo: "", email: "", settings: {}});
    const { sendSuccess, sendError } = useContext(NotificationContext);

    const fetchUser = async () => {
        try {
            const client = new ApiClient();
            const result = await client.getLoggedUser();
            if (result.settings === null) {
                result.settings = {};
            }
            setUser(result);
        }
        catch (e) {
            console.error(e);
        }
    }

    const saveUser = async () => {
        try {
            const client = new ApiClient();
            const data = {...user};
            const result = await client.editLoggedUser(data);
            setUser(result);
            sendSuccess("Profile updated!");
        }
        catch (e) {
            sendError(e);
        }
    }

    const saveUserSettings = async(settings) => {
        try {
            const client = new ApiClient();
            const data = {...user};
            data.settings = settings;
            const result = await client.editLoggedUser(data);
            setUser(result);
            sendSuccess("Profile updated!");
        }
        catch (e) {
            sendError(e);
        }
    }

    const update = function(updatedValue) {
        let newUser = {
            ...user
        };
        for (const key in updatedValue) {
            newUser[key] = updatedValue[key];
        }
        setUser(newUser);
    }

    const updateSetting = function(setting, value) {
        let newSettings = {
            ...user.settings
        };
        newSettings[setting] = value;
        update({ settings: newSettings});
    }

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <>
            <Typography level="h1">Settings</Typography>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                <Grid sm={3}>
                    <List>
                        <ListItem >
                            <ListItemButton
                                            href="/settings/account"
                                            color="neutral"
                                            selected={(location.pathname === '/settings/account')}
                                            onClick={(e) => navigate("/settings/account")}>
                                <ListItemDecorator><FontAwesomeIcon icon={faUser} /></ListItemDecorator>
                                <ListItemContent>Account</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                            href="/settings/authentication"
                                            color="neutral"
                                            selected={(location.pathname === '/settings/authentication')}
                                            onClick={(e) => navigate("/settings/authentication")}>
                                <ListItemDecorator><FontAwesomeIcon icon={faKey} /></ListItemDecorator>
                                <ListItemContent>Authentication</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                            href="/settings/patreon"
                                            color="neutral"
                                            selected={(location.pathname === '/settings/patreon')}
                                            onClick={(e) => navigate("/settings/patreon")}>
                                <ListItemDecorator><FontAwesomeIcon icon={faPatreon} /></ListItemDecorator>
                                <ListItemContent>Patreon</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                            href="/settings/display"
                                            color="neutral"
                                            selected={(location.pathname === '/settings/display')}
                                            onClick={(e) => navigate("/settings/display")}>
                                <ListItemDecorator><FontAwesomeIcon icon={faDisplay} /></ListItemDecorator>
                                <ListItemContent>Display</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListDivider />
                        <ListItem>
                            <ListItemButton
                                            href="/settings/defaults"
                                            color="neutral"
                                            selected={(location.pathname === '/settings/defaults')}
                                            onClick={(e) => navigate("/settings/defaults")}>
                                <ListItemDecorator><FontAwesomeIcon icon={faTree} /></ListItemDecorator>
                                <ListItemContent>Default specializations</ListItemContent>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Grid>
                <Grid sm={9}>
                    <Sheet style={{ paddingLeft: "30px", marginTop: "10px" }}>
                        <Routes>
                            <Route path="/" element={<SettingsAccountFragment user={user} />} />
                            <Route path="/account" element={<SettingsAccountFragment user={user} />} />
                            <Route path="/authentication" element={<SettingsAuthenticationFragment user={user} />} />
                            <Route path="/patreon" element={<SettingsPatreonFragment />} />
                            <Route path="/display" element={<SettingsDisplayFragment user={user} onReload={fetchUser} />} />
                            <Route path="/defaults" element={<SettingsDefaultsFragment user={user} onReload={fetchUser} />} />
                        </Routes>
                    </Sheet>
                </Grid>
            </Grid>
        </>
    );
}

