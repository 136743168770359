import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Sheet from '@mui/joy/Sheet';
import IconButton from '@mui/joy/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBars, faDragon, faGear, faKey, faSignOut, faTableCells} from '@fortawesome/free-solid-svg-icons'
import {
    Avatar,
    Divider,
    Dropdown, Link,
    List,
    ListItem,
    ListItemButton,
    Menu,
    MenuButton,
    MenuItem,
    Typography
} from "@mui/joy";
import {useContext} from "react";
import AuthContext from "../contexts/auth-context";
import Button from "@mui/joy/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {faHammer} from "@fortawesome/pro-solid-svg-icons";

export default function Header() {
    const location = useLocation();
    const authContext = useContext(AuthContext);
    const { isAuthenticated, user } = useContext(AuthContext);
    const hideHeaderPaths = ['/sign-in','/sign-up','/wait-list'];
    const navigate = useNavigate();

    const logout = () => {
        authContext.logout();
        navigate("/");
    }

    return (
        <>
        {!hideHeaderPaths.includes(location.pathname) &&
            <Sheet
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    height: 'var(--Header-height)',
                    zIndex: 9995,
                    gap: 1,
                    pt: '10px',
                    pb: '10px',
                    borderBottom: '1px solid',
                    borderColor: 'background.level1',
                    boxShadow: 'sm',
                }}
            >
                <GlobalStyles
                    styles={(theme) => ({
                        ':root': {
                            '--Header-height': '44px'
                        },
                    })}
                />


                <List
                    role="menubar"
                    orientation="horizontal"
                    variant="plain"
                    size="lg"
                    sx={{
                        my: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        '--List-radius': '0px',
                        '--List-padding': '8px',
                        '--ListDivider-gap': '8px',
                    }}
                >
                    <ListItem role="none">
                        <Typography level="h3"><FontAwesomeIcon icon={faDragon}/> RaidLead</Typography>
                    </ListItem>
                    <ListItem role="listitem">
                        <ListItemButton
                            role="menuitem"
                            component="a"
                            href="/"
                        >
                            Home
                        </ListItemButton>
                    </ListItem>
                    <ListItem role="listitem">
                        <ListItemButton
                            role="menuitem"
                            component="a"
                            href="/strategies"
                        >
                            Strategies
                        </ListItemButton>
                    </ListItem>
                    <ListItem role="listitem">
                        <ListItemButton
                            role="menuitem"
                            component="a"
                            href="/blog"
                        >
                            Blog
                        </ListItemButton>
                    </ListItem>
                    <ListItem role="listitem">
                        <ListItemButton
                            role="menuitem"
                            component="a"
                            href="/help/overview"
                        >
                            Help
                        </ListItemButton>
                    </ListItem>
                </List>

                {isAuthenticated ? (
                    <Dropdown>
                        <MenuButton variant="plain">
                            <Avatar variant="outlined" sx={{marginRight: '15px'}}/> {user.pseudo}
                        </MenuButton>
                        <Menu placement="bottom-end" size="md">
                            <MenuItem component="a" href="/my-templates" style={{width: "200px"}}>
                                <FontAwesomeIcon icon={faTableCells}/>My templates
                            </MenuItem>
                            <MenuItem component="a" href="/settings" style={{width: "200px"}}>
                                <FontAwesomeIcon icon={faGear}/>Settings
                            </MenuItem>
                            <Divider/>
                            {user.roles.includes("admin") &&
                                <MenuItem component="a" href="/admin"><FontAwesomeIcon icon={faHammer}/>
                                    Admin
                                </MenuItem>
                            }
                            <MenuItem onClick={logout}><FontAwesomeIcon icon={faSignOut}/>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Dropdown>
                ) : (
                    <Button variant="plain"
                            size="lg"
                            color="neutral"
                            component="a"
                            href="/sign-in"
                            startDecorator={<FontAwesomeIcon icon={faKey}/>}>Login</Button>
                )}
            </Sheet>}
        </>
    );
}
