import {
    FormControl,
    FormLabel,
    Grid,
    Input,
    Select,
    Option,
    Table,
    Typography,
    Stack,
    Checkbox,
    ButtonGroup, Divider
} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import React, {useCallback, useEffect, useState} from "react";
import ChooseIconButton from "../components/choose-icon-button";
import Button from "@mui/joy/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faGear, faPlus, faRemove, faSave, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import LogicTags from "../components/logic-tags";
import WoWIcon from "../components/wow-icon";
import IconInput from "../components/icon-input";
import SelectTarget from "../components/select-target";
import ImportDialog from "../components/import-dialog";
import ActionTargetDialog from "../components/action-target-dialog";
import DurationInput from "../components/duration-input";
import IconButton from "@mui/joy/IconButton";
import { v4 as uuidv4 } from 'uuid';
import {useNavigate} from "react-router-dom";
import RichTextEditor from "../components/rich-text-editor";

function HelpPageForm(props) {
    const [url, setUrl] = useState(props.value.url);
    const [title, setTitle] = useState(props.value.title);
    const [contents, setContents] = useState(props.value.contents);

    const [actions, setActions] = useState([]);

    const handleSaveClicked = function(e) {
        const help = {
            url,
            title,
            contents
        };
        props.onSave(help);
    }

    const handleCancelClicked = function(e) {
        props.onCancel();
    }

    useEffect(() => {
        setUrl(props.value.url ?? "");
    }, [props.value.url]);

    useEffect(() => {
        setTitle(props.value.title ?? "");
    }, [props.value.title]);

    useEffect(() => {
        setContents(props.value.contents ?? "");
    }, [props.value.contents]);

    return (
        <>
            <Stack>
                <RichTextEditor value={contents}
                                onChange={setContents} />
                <Divider sx={{ mt: 1, mb: 1 }}/>
                <Stack direction="row"
                       spacing={2}
                       style={{ marginTop: "10px" }}
                       justifyContent="flex-end">

                    <Button size="md"
                            variant="soft"
                            color="neutral"
                            onClick={handleCancelClicked}>
                        Cancel
                    </Button>
                    <Button size="md"
                            variant="solid"
                            startDecorator={<FontAwesomeIcon icon={faSave} />}
                            onClick={handleSaveClicked}>
                        Save
                    </Button>
                </Stack>
            </Stack>
        </>
    )
}
export {
    HelpPageForm
};
