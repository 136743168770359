import {Alert, FormControl, FormLabel, Input, Link, Typography} from "@mui/joy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDragon, faInfo, faWarning} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/joy/Button";
import Sheet from "@mui/joy/Sheet";
import * as React from "react";
import {useEffect, useState} from "react";

export default function WaitListForm(props) {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const handleWaitListClicked = function(e) {
        setLoading(true);
        props.onSubmit({ email });
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleWaitListClicked();
        }
    };

    useEffect(() => {
        setLoading(false);
    }, [props.error]);

    return (
        <>
            <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                    // html input attribute
                    name="email"
                    type="email"
                    placeholder="johndoe@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
            </FormControl>
            <Alert color="primary" startDecorator={<FontAwesomeIcon icon={faInfo} />}>
                For eligibility to receive an invitation, a verified email address is required. Please ensure your email is accurate and active.
            </Alert>
            <Button loading={loading} sx={{ mt: 1 /* margin top */ }} onClick={handleWaitListClicked}>Join the Waitlist</Button>
            <Typography
                endDecorator={<Link href="/blog">Go to our blog</Link>}
                fontSize="sm"
                sx={{ alignSelf: 'center' }}
            >
                Want more news?
            </Typography>
        </>
    );
}
