
export function importBase64(base64) {
    let json = b64DecodeUnicode(base64);
    json = json.replace(/'/g, '"');
    const result = JSON.parse(json);
    return result;
}

function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}
