import {Divider, Stack, Typography} from "@mui/joy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard, faDisplay, faSave} from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/joy/Button";
import React, {useContext, useState} from "react";
import {faPatreon} from "@fortawesome/free-brands-svg-icons";
import SettingsDisplayForm from "../../forms/settings-display-form";
import ApiClient from "../../utils/api-client";
import NotificationContext from "../../contexts/notification-context";

export default function SettingsDisplayFragment(props) {
    const [user, setUser] = useState(props.user);
    const { sendSuccess, sendError } = useContext(NotificationContext);

    const saveUserSettings = async(settings) => {
        try {
            const client = new ApiClient();
            const data = {...user};
            data.settings = settings;
            const result = await client.editLoggedUser(data);
            setUser(result);
            sendSuccess("Profile updated!");
            props.onReload();
        }
        catch (e) {
            sendError(e);
        }
    }

    return (
        <>
            <Typography level="title-lg"><FontAwesomeIcon icon={faDisplay} />&nbsp;Display</Typography>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }}/>
            <SettingsDisplayForm settings={props.user.settings} onSave={(settings) => saveUserSettings(settings)} />
        </>
    );
}
