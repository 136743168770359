import {Box, ButtonGroup, Card, CardActions, CardContent, CardOverflow, Grid, Link, Stack, Typography} from "@mui/joy";
import PlayerFrame from "./player-frame";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "@mui/joy/Button";
import {faFileImport, faPlus, faMinus, faShield, faHatWizard, faCopy} from '@fortawesome/free-solid-svg-icons';
import Sheet from "@mui/joy/Sheet";
import React, {useEffect} from 'react';
import {importBase64} from "../../data/ImporterLogic";
import ImportDialog from "./import-dialog";
import NotificationContext from "../contexts/notification-context";
import RaidFrame from "./raid-frame";
import Chip from "@mui/joy/Chip";
import {faBowArrow, faPlusSquare, faSword, faTable} from "@fortawesome/pro-solid-svg-icons";
import ApiClient from "../utils/api-client";

export default function RosterFrame(props) {
    const [raid, setRaid] = React.useState([]);
    const [summary, setSummary] = React.useState({ roles: {tank: 0, healer: 0, range: 0, melee: 0}, passives: []});
    const { sendError, sendSuccess } = React.useContext(NotificationContext);

    const handleRaidChanged = function(e) {
        setRaid(e.value);
        if (props.onChange) {
            props.onChange({ raid: e.value });
        }
    }

    const handleBuildNoteClicked = function(e) {
        if (props.onSubmit) {
            props.onSubmit();
        }
    }

    const fetchRosterSummary = async() => {
        try {
            const client = new ApiClient();
            const summary = await client.summarizeRoster({
                roster: raid
            });
            setSummary(summary);
        }
        catch (e) {
            sendError(e);
        }
    }

    useEffect(() => {
        fetchRosterSummary();
    }, [raid]);

    return (
        <Card variant="soft" color="primary" elevation={3}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid xs={6}>
                        <Typography level="title-md" fontWeight="bold" startDecorator={<FontAwesomeIcon icon={faTable} />}>Your roster</Typography>
                        <Typography level="body-sm" fontWeight="bold">Import your roster with our addon <Link target="_blank" href="https://www.curseforge.com/wow/addons/raidlead-assistant">RaidLead Assistant</Link>.</Typography>
                        <Stack direction="column" gap={1} sx={{ marginTop: "5px" }}>
                            <Typography level="body-sm">{raid.length} players with the following composition :</Typography>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <Chip size="md" color="soft" startDecorator={<FontAwesomeIcon icon={faShield} />}>{summary.roles.tank} tanks</Chip>
                                <Chip size="md" color="soft" startDecorator={<FontAwesomeIcon icon={faPlusSquare} />}>{summary.roles.healer} healers</Chip>
                                <Chip size="md" color="soft" startDecorator={<FontAwesomeIcon icon={faBowArrow} />}>{summary.roles.range} range dps</Chip>
                                <Chip size="md" color="soft" startDecorator={<FontAwesomeIcon icon={faSword} />}>{summary.roles.melee} melee dps</Chip>
                            </Box>
                            <Typography level="body-sm">Available passives :</Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, lineHeight: '35px' }}>
                                {summary.passives.map((passive, index) => (
                                    <Chip key={index} size="md" color="soft">{passive}</Chip>
                                ))}
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid xs={6}>
                        <RaidFrame onChange={handleRaidChanged}/>
                    </Grid>
                </Grid>
            </CardContent>
            <CardOverflow>
                <CardActions buttonFlex="1" gap={2}>
                    <Button size="md" color="primary" onClick={handleBuildNoteClicked}>Apply this strategy</Button>
                    <Button size="md" color="primary" variant="outlined" startDecorator={<FontAwesomeIcon icon={faCopy} />}>Copy to My templates</Button>
                </CardActions>
            </CardOverflow>
        </Card>
    );
}
