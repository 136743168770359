import React, {useContext} from 'react';
import AuthContext from "./auth-context";

const NotificationContext = React.createContext({
    alerts: [],
    sendSuccess: () => {},
    sendError: () => {},
    clear: () => {}
});

const NotificationProvider = (props) => {
    const [alerts, setAlerts] = React.useState([]);
    const { logout } = useContext(AuthContext);
    const sendSuccess = (text) => {
        const id = new Date().getTime(); // Unique ID for each alert
        setAlerts(currentAlerts => [...currentAlerts, { id, type: 'success', text }]);
        setTimeout(() => removeAlert(id), 5000); // Remove alert after 5 seconds
    };
    const sendError = (error) => {

        const id = new Date().getTime(); // Unique ID for each alert
        setAlerts(currentAlerts => [...currentAlerts, { id, type: 'danger', text: error.message }]);
        setTimeout(() => removeAlert(id), 15000); // Remove alert after 5 seconds
        if (error.message.includes('Unauthorized')) {
            logout();
        }
    };

    const removeAlert = (id) => {
        setAlerts(currentAlerts => currentAlerts.filter(alert => alert.id !== id));
    };
    const clear = () => {
        setAlerts([]);
    };
    return (
        <NotificationContext.Provider
            value={{
                sendSuccess, sendError, clear, alerts
            }}
        >
            {props.children}
        </NotificationContext.Provider>
    );
};

export { NotificationProvider };
export default NotificationContext;
