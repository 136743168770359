import Sheet from "@mui/joy/Sheet";
import {Card, CircularProgress, Grid, ListItemDecorator, Tab, TabList, Tabs, Typography} from "@mui/joy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartGantt, faHeadset, faNoteSticky} from "@fortawesome/free-solid-svg-icons";
import AssignationTable from "./assignation-table";
import RosterAbilitiesTable from "./roster-abilities-table";
import CodeWithLineBreaks from "./code-with-line-breaks";
import React, {useState} from "react";
import {faBolt, faBrain, faCircleBolt, faMicrochip, faTimer} from "@fortawesome/pro-solid-svg-icons";

export default function AssignationFrame(props) {
    const [tab, setTab] = useState(0);

    return (
        <>
        {(props.computing === true) ? (
            <>
                <Sheet variant="soft" sx={{ textAlign: "center", padding: "35px" }}>
                    <CircularProgress />
                    <Typography level="title-lg">Please wait while we build our assignments.</Typography>
                </Sheet>
            </>
        ) : (
            <>
                <Tabs aria-label="Icon tabs" value={tab}
                      onChange={(event, newValue) => setTab(newValue)}>
                    <TabList>
                        <Tab>
                            <ListItemDecorator>
                                <FontAwesomeIcon icon={faHeadset}/>
                            </ListItemDecorator>
                            Assignations
                        </Tab>
                        <Tab>
                            <ListItemDecorator>
                                <FontAwesomeIcon icon={faChartGantt}/>
                            </ListItemDecorator>
                            Graph
                        </Tab>
                        <Tab>
                            <ListItemDecorator>
                                <FontAwesomeIcon icon={faNoteSticky}/>
                            </ListItemDecorator>
                            Note
                        </Tab>
                        <Tab>
                            <ListItemDecorator>
                                <FontAwesomeIcon icon={faBolt}/>
                            </ListItemDecorator>
                            Statistics
                        </Tab>
                    </TabList>
                </Tabs>
                {props.result !== null &&
                    <Sheet variant="soft" elevation={2} sx={{ padding: "10px", marginTop: -2 }}>
                        {tab === 0 &&
                            <>
                                <Typography level="body-sm">Here is the list of assignations we have chosen for your note.</Typography>
                                <AssignationTable assignations={props.result.assignations} />
                            </>
                        }
                        {tab === 1 &&
                            <>
                                <Typography level="body-sm">Contains all abilities of your raid and their usage.</Typography>
                                <RosterAbilitiesTable statistics={props.result.statistics ?? []} maxDuration={props.result.maxDuration} />
                            </>
                        }
                        {tab === 2 &&
                            <>
                                <Typography level="body-sm">You can copy-paste this Method Raid Tools note to your addon.</Typography>
                                <Card style={{ marginTop: "15px", marginBottom: "15px" }}>
                                    <CodeWithLineBreaks text={props.result.note} />
                                </Card>
                            </>
                        }
                        {tab === 3 &&
                            <>
                                <Typography level="body-sm">Here is the statistics of the assignations :</Typography>
                                {props.result.efficiency != null &&
                                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                                    <Grid xs={12} sm={4} md={4}>
                                        <Sheet variant="soft"
                                               color="success"
                                               inversedColors
                                               sx={{ p: 2,
                                                    borderRadius: 10,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography level="body-md"
                                                        color="success"
                                                        fontWeight="bold"
                                                        startDecorator={<FontAwesomeIcon icon={faBrain} />}>Efficiency</Typography>
                                            <Typography level="h4" color="success">{(props.result.efficiency * 100).toFixed(2)}%</Typography>
                                        </Sheet>
                                    </Grid>
                                    <Grid xs={12} sm={4} md={4}>
                                        <Sheet variant="soft"
                                               color="success"
                                               inversedColors
                                               sx={{ p: 2,
                                                    borderRadius: 10,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography level="body-md"
                                                        color="success"
                                                        fontWeight="bold"
                                                        startDecorator={<FontAwesomeIcon icon={faTimer} />}>Time</Typography>
                                            <Typography level="h4" color="success">{props.result.time.toFixed(0)}ms</Typography>
                                        </Sheet>
                                    </Grid>
                                    <Grid xs={12} sm={4} md={4}>
                                        <Sheet variant="soft"
                                               color="success"
                                               inversedColors
                                               sx={{ p: 2,
                                                    borderRadius: 10,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography level="body-md"
                                                        color="success"
                                                        fontWeight="bold"
                                                        startDecorator={<FontAwesomeIcon icon={faMicrochip} />}>Iterations</Typography>
                                            <Typography level="h4" color="success">1</Typography>
                                        </Sheet>
                                    </Grid>
                                </Grid>
                                }
                            </>
                        }
                    </Sheet>
                }
            </>
        )}
        </>
    );
}
